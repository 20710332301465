import React, {Component} from 'react';
import avatar from '../assets/images/blog-avatar.png';
import FeaturedImageLaliga from '../assets/images/blog-featured-image-mls.png';
import FeaturedImageNfts from '../assets/images/blog-featured-image-nfts.png';
import FeaturedImageStarware from '../assets/images/blog-featured-image-starkware.jpg';
import BlogItem from './BlogItem';

export default class Blog extends Component {
  render() {
    return (
      <div className="blog-section">
        <div className="container">
          <div className="blog-title-wrapper">
            <h3 className="blog-title">THIS JUST IN FROM THE FRONT OFFICE</h3>
            <a
              href="https://blog.greenparksports.com"
              target="_blank"
              className="more-blog"
            >
              More News <span>→</span>
            </a>
          </div>
          <div className="row">
            <BlogItem
              featuredImage={FeaturedImageLaliga}
              title="GreenPark Sports To Launch Major League Soccer in New Multi-Year Partnership"
              author="GreenPark Team"
              avatar={avatar}
              date="24 FEB 2022"
              readTime="3 Min"
              blogUrl="https://blog.greenparksports.com/mls-launches-in-greenpark/"
            />
            <BlogItem
              featuredImage={FeaturedImageNfts}
              title={`GreenPark Sports Debuts “Virtues” NFTs - First Drop for its Sports Fan Metaverse, Built on Immutable X`}
              author="GreenPark Team"
              avatar={avatar}
              date="02 NOV 2021"
              readTime="2 Min"
              blogUrl="https://blog.greenparksports.com/greenpark-sports-debuts-virtues-nfts-first-drop-for-its-sports-fan-metaverse-built-on-immutable-x/"
            />
            <BlogItem
              featuredImage={FeaturedImageStarware}
              title="GREENPARK SPORTS RAISES $31M AS COMPANY READIES FOR FIRST NFT DROP"
              author="GreenPark Team"
              avatar={avatar}
              date="22 OCT 2021"
              readTime="3 Min"
              blogUrl="https://blog.greenparksports.com/greenpark-sports-raises-31m-as-company-readies-for-first-nft-drop/"
            />
          </div>
        </div>
      </div>
    );
  }
}
