import React from 'react';
// import AppleAppStore from '../assets/images/app-store-cta.png';
// import VideoBg from 'reactjs-videobg';
import poster from '../assets/images/hero-bg.jpg';
import partnersBanner from '../assets/images/partners-banner.png';
// import mp4 from '../assets/videos/hero-bg-video.mp4';
// import Logo from './Logo';
// import GooglePlayStore from '../assets/images/play-store-cta.png';
// import SamsungAppStore from '../assets/images/galaxy-store-cta.png';

const Hero = () => {
  return (
    <div className="hero">
      <div className="top-section">
        {/*
        <VideoBg
          poster={poster}
          wrapperClass="hero-video-wrapper"
          videoClass="hero-video"
        >
          <VideoBg.Source src={mp4} type="video/mp4" />
          * hack for single child error (don't need ogg or webm sources anymore) *
          <div />
        </VideoBg>
       */}
        {/* // TODO: placeholder for now until blogpost link comes in: */}
        <a href="https://nft.greenparksports.com/laliga">
          <img
            src={poster}
            alt="Green Park Sports"
            className="homepage-hero img-fluid"
          />
        </a>
        {/* <a href="https://app.adjust.com/5qr9jx7_3fpdbeb?campaign=Website&adgroup=TopRight">
          <div className="logo">
            <h1 className="videoCTA-title">PLAY TODAY</h1>
          </div>
        </a> */}
        {/* <h1 className="hero-title">
          Download the epic mobile metaverse for sports fans
        </h1>
        <p className="partners-heading">Official Partners</p>
        <div className="downloadcta-buttons">
          <a
            href="https://app.adjust.com/5qr9jx7?campaign=Website2&adgroup=SectionOneDownload"
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
          >
            <img
              src={AppleAppStore}
              alt="Apple App Store Download CTA"
              className="download-cta-img img-fluid"
            />
          </a>
          <a
            href="https://app.adjust.com/3fpdbeb?campaign=Website2&adgroup=SectionOneDownload"
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
          >
            <img
              src={GooglePlayStore}
              alt="Google Play Store Download CTA"
              className="download-cta-img img-fluid"
            />
          </a>
          <a
            href="https://apps.samsung.com/appquery/appDetail.as?appId=com.greenparksports.game_sg"
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
          >
            <img
              src={SamsungAppStore}
              alt="Samsung Galaxy Store Download CTA"
              className="download-cta-img img-fluid"
            />
          </a> 
        </div> */}
      </div>
      {/* <div className="bottom-section"> */}
      <div className="official-partners">
        <img
          src={partnersBanner}
          alt="Partners Banner"
          className="partners-banner img-fluid"
        />
      </div>
      {/* </div> */}
    </div>
  );
};

export default Hero;
