import React from 'react';
import Blog from './Blog';
// import Investors from './Investors';
// import Team from './Team';

const AllThingsGreen = () => {
  return (
    <div className="all-things-green-section">
      <div className="container">
        <div className="row">
          <div className="col col-lg-7">
            <h2 className="section-title green">All Things Green</h2>
          </div>
        </div>
      </div>
      <Blog />
      {/* <Team /> */}
      {/* <Investors /> */}
    </div>
  );
};

export default AllThingsGreen;
