import React from 'react';

const Noise = () => {
  return (
    <div className="noise-section">
      <div className="container section-content">
        <div className="row align-items-center">
          <h1 className="section-title deep-blue">
            GET READY TO MAKE SOME NOISE
          </h1>
          <p className="section-text">
            Sure, we’re starting small. But so did all the great franchises in
            the history of sports. Just know we’ve got big plans for social,
            we’re leaving it all on the field, and there are great things on the
            horizon.
          </p>
          <p className="section-text">Stay close. We’ll keep you posted.</p>
        </div>
      </div>
    </div>
  );
};

export default Noise;
