import React from 'react';
import fansGreenie from '../assets/images/fans-greenie.png';

const Fans = () => {
  return (
    <div className="fans-section">
      <div className="section-splitter">
        {/* <div className="splitter-inner">
          <p className="attention">
            <span role="img" aria-label="callout icon">
              ⚽️
            </span>{' '}
            We shoot, we score!
          </p>
          <a
            className="splitter-cta"
            href="http://blog.greenparksports.com/mls-launches-in-greenpark/"
            target="_blank"
            rel="noopener noreferrer"
          >
            MLS JOINS GREENPARK.<span>→</span>
          </a>
        </div> */}
      </div>
      <div className="container section-content">
        <div className="row align-items-center">
          <div className="col-xs-12 col-lg-5">
            <div className="title-text-section">
              <h2 className="section-title green">
                MAY THE BEST FANS WIN!<span className="fanstrademark">™</span>
              </h2>
              <p className="section-text">
                Welcome to the virtual sports universe of GreenPark – the first
                mobile proving ground for sports fans. It’s time to suit up for
                your favorite team, join your friends and prove, once and for
                all, that you’re the Most Valuable Fan. Game on.
              </p>
            </div>
          </div>
          <div className="col-xs-12 col-lg-7">
            <div className="section-image-wrapper">
              <img
                src={fansGreenie}
                alt="fans greenie paint"
                className="img-fluid section-image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fans;
