import React from 'react';
import InfoPage from './InfoPage';

const PrivacyPolicy = () => (
  <InfoPage title="GREENPARK PRIVACY POLICY">
    <div className="subpage-main-content">
      <h1>GreenPark Privacy Policy</h1>
      <h2>
        <strong>Last Updated: January 11, 2021</strong>
      </h2>
      <p>
        At GreenPark, we are building the future of fandom. Our goal is to
        create a new sports fan experience for all types of fans. As part of
        creating that experience for you, we take steps to protect your privacy
        and the security of the personal information we collect when you use any
        of the GreenPark-branded online and mobile products and services,
        including, without limitation, the GreenParkSports.com website and the
        &ldquo;GreenPark&rdquo; app (collectively, the{' '}
        <em>&ldquo;Platform&rdquo;</em>). This Privacy Policy describes what
        personal information we collect when you use the Platform, how we use
        and share that personal information, and the privacy choices you have.
      </p>
      <p>&nbsp;</p>
      <h2>
        <strong>WHAT INFORMATION WE COLLECT AND HOW WE COLLECT</strong>
      </h2>
      <p>
        The table below describes the types of personal information we may
        collect (including through third-party service providers) in connection
        with the Platform:
      </p>
      <table>
        <tbody>
          <tr>
            <td style={{width: '15%'}}>
              <p>
                <strong>Contact Information:</strong>
              </p>
            </td>
            <td style={{width: '85%'}}>
              <p>
                This is any information that allows us to identify you
                individually (or your account on the Platform) and to contact
                you, such as your name, date of birth, country or state of
                residence, email address, phone number, mailing address, and
                your account username or screen name.
              </p>
            </td>
          </tr>
          <tr>
            <td style={{width: '15%'}}>
              <p>
                <strong>Device Information:</strong>
              </p>
            </td>
            <td style={{width: '85%'}}>
              <p>
                This is information concerning the computer or mobile device
                that you use to access the Platform, such as IP address (i.e., a
                numerical address that identifies a computer or mobile device on
                the Internet or a local network), device ID (such as a
                device&rsquo;s advertising identifier, or other device-level
                identifier), device type, type of operating system and version,
                etc.
              </p>
            </td>
          </tr>
          <tr>
            <td style={{width: '15%'}}>
              <p>
                <strong>Usage Information:</strong>
              </p>
            </td>
            <td style={{width: '85%'}}>
              <p>
                This is information about your use of the Platform, such as
                areas/pages visited, games played, advertisements viewed,
                searches performed, amount of time you&rsquo;ve spent on using
                the Platform or particular areas or features of the Platform,
                etc.
              </p>
            </td>
          </tr>
          <tr>
            <td style={{width: '15%'}}>
              <p>
                <strong>Transaction Information:</strong>
              </p>
            </td>
            <td style={{width: '85%'}}>
              <p>
                This is information about an in-app purchase you make or an
                in-app reward or prize you receive on the Platform, such as the
                date and value of your purchase or reward/prize.
              </p>
            </td>
          </tr>
          <tr>
            <td style={{width: '15%'}}>
              <p>
                <strong>Preference Information:</strong>
              </p>
            </td>
            <td style={{width: '85%'}}>
              <p>
                This is information about certain interests and preferences that
                you indicate to us, such as your favorite sports, sports teams,
                and athletes, and also including your preferences (i.e., opt-in
                and opt-out) concerning: (1) your receipt of marketing
                communications (emails, SMS, etc.) from us and/or from third
                parties; and/or (2) our sharing of your information for third
                parties&rsquo; direct marketing uses.
              </p>
            </td>
          </tr>
          <tr>
            <td style={{width: '15%'}}>
              <p>
                <strong>Employment Related Information:</strong>
              </p>
            </td>
            <td style={{width: '85%'}}>
              <p>
                <em>
                  [Applicable only if you apply for a job with us on the
                  Platform]
                </em>{' '}
                This is information about your employment history and work
                experiences that you include in your employment application,
                resume or cover letter.
              </p>
            </td>
          </tr>
          <tr>
            <td style={{width: '15%'}}>
              <p>
                <strong>Diagnostic Information:</strong>
              </p>
            </td>
            <td style={{width: '85%'}}>
              <p>
                This is information collected from your computer or mobile
                device for the purposes of measuring technical diagnotics
                related to the Platform, such as crash data and performance
                data.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        We and/or third-party service providers may also collect
        &ldquo;aggregate information&rdquo; which is information pertaining to
        users of the Platform as a group, such as user traffic data and
        demographic data, where individual user identities have been removed,
        and which information is not linked or reasonably linkable to a
        particular individual, household or device.{' '}
        <em>
          Aggregate information is <u>not</u> considered personal information
        </em>
        .
      </p>
      <p>
        Generally, the collection of personal information takes place in the
        following ways:
      </p>
      <ul>
        <li>
          <strong>Directly from you:</strong> For example, when you create an
          account on the Platform, or when you sign up for an event, promotion,
          survey, etc. on the Platform, or when you contact us on the Platform,
          we collect your Contact Information as provided by you.
        </li>
      </ul>
      <ul>
        <li>
          <strong>
            Automatic collection through use of cookies and similar data
            collection technologies:
          </strong>{' '}
          When you use the Platform, we may automatically collect certain
          information such as your Device Information and Usage Information,
          through cookies, pixels, tags, etc. See the{' '}
          <strong>
            <u>
              <a href="#cookies-and-similar-technologies">
                COOKIES AND SIMILAR TECHNOLOGIES
              </a>
            </u>
          </strong>{' '}
          section below for details.
        </li>
      </ul>
      <ul>
        <li>
          <strong>From third parties:</strong> We may receive or acquire
          information about you from third parties (including data brokers) to
          help us verify, validate, augment, enhance, and/or analyze information
          we have collected ourselves. For example, we may receive in-app
          purchase history data from third-party app stores to help us
          understand our users&rsquo; purchasing habits and tendencies.
        </li>
      </ul>
      <p>&nbsp;</p>
      <h2>
        <strong>HOW WE USE YOUR INFORMATION</strong>
      </h2>
      <p>
        Generally, we may use (including through third-party service providers)
        your personal information for one or more of the following purposes (as
        applicable):
      </p>
      <ul>
        <li>
          Providing and maintaining the Platform&rsquo;s functionality,
          including, without limitation: authenticating users, setting up and
          administering user accounts, enabling features, preventing fraud,
          implementing security measures, measuring and improving performance,
          troubleshooting, providing customer support, and personalizing and
          enhancing user experience;
        </li>
      </ul>
      <ul>
        <li>
          Communicating with you in relation to the Platform, such as sending
          you notices and alerts concerning your account or one or more
          transactions on your account, and notifying you about new features or
          changes to the Platform, about upcoming events or promotions that may
          be of interest to you, about changes to this Privacy Policy and/or our
          Terms of Service, etc.
        </li>
      </ul>
      <ul>
        <li>
          Completing a transaction you requested on the Platform, such as when
          you make an in-app purchase or earn an in-app reward/prize;
        </li>
      </ul>
      <ul>
        <li>
          Enabling your participation in an event, promotion, survey, etc. for
          which you have signed up with us;
        </li>
      </ul>
      <ul>
        <li>Responding to a request or question from you;</li>
      </ul>
      <ul>
        <li>
          Evaluating a job application submitted by you and contacting you about
          your application;
        </li>
      </ul>
      <ul>
        <li>
          Displaying advertisements (on and off the Platform) that may be of
          interest to you, including advertisements that are tailored to your
          interests and activities on the Platform; for example, when you use
          our mobile apps, you may see in-app advertisements from third parties,
          and some of them may be programmatic (automated) advertisements.
        </li>
      </ul>
      <ul>
        <li>
          Conducting market research, performing data analysis, and compiling
          analytics to help us and our business partners better understand the
          interests, activities, demographics, and other attributes of Platform
          users;
        </li>
      </ul>
      <ul>
        <li>
          Detecting, investigating, and preventing fraud and other illegal
          activities, protecting the integrity and safety of the Platform,
          protecting our legal rights and those of others, and/or enforcing our
          Terms of Service and other terms and policies for the Platform.
        </li>
      </ul>
      <p>&nbsp;</p>
      <h2>
        <strong>SHARING OF YOUR INFORMATION WITH THIRD PARTIES</strong>
      </h2>
      <p>
        We may share your personal information with third parties in certain
        circumstances, including the following:
      </p>
      <ul>
        <li>
          <strong>With service providers</strong> &ndash; We may share your
          information with vendors and contractors who need the information in
          order to perform services for us in connection with the Platform.
          These services may include (but are not limited to): operational and
          technical support; transaction processing; mobile attribution and
          analytics; data hosting, processing, reporting, and storage; data
          security; and marketing and advertising support. Generally, we take
          steps to require that our service providers use your personal
          information shared by us solely for the purposes of performing
          services for us and that they maintain the confidentiality, security
          and integrity of such information.
        </li>
      </ul>
      <ul>
        <li>
          <strong>With business partners</strong> &ndash; We may share your
          information with our business partners to enable them to market and
          advertise directly to you. Our business partners may include (but are
          not limited to): sports leagues and sports teams (professional,
          collegial, etc.) that have licensed their brands and/or other
          intellectual property to us for use on the Platform; advertisers and
          sponsors that have partnered with us to advertise, promote, or
          integrate their products, services, and/or brands on the Platform; and
          ad networks and ad mediation platforms that have partnered with us to
          serve programmatic advertisements on the Platform.
        </li>
      </ul>
      <ul>
        <li>
          <strong>As requested by you or with your consent </strong>&ndash; To
          the extent you affirmatively request or consent to our sharing of your
          information with one or more third parties, we will do so based on
          your request or consent.
        </li>
      </ul>
      <ul>
        <li>
          <strong>In a business transition</strong> &ndash; In the event we go
          through a business transition, such as a merger, acquisition,
          divestiture, restructuring, reorganization, dissolution, bankruptcy or
          sale of all or a portion of our assets, we may disclose your personal
          information to the party or parties of such transaction.
        </li>
      </ul>
      <ul>
        <li>
          <strong>To protect our rights</strong> &ndash; We reserve the right to
          release your information to law enforcement agencies if we determine,
          in our sole judgment, that either you have violated our policies, or
          the release of your personal information may protect the rights,
          property, or safety of GreenPark, or another person.
        </li>
      </ul>
      <ul>
        <li>
          <strong>As required by law or in response to a legal process</strong>{' '}
          &ndash; We may disclose information about you to law enforcement or in
          response to a legal process if we have a good faith belief that we are
          required to do so by law, including for national security or law
          enforcement requirements. This also includes sharing your information
          with governmental entities, or third parties in response to subpoenas,
          court orders, other legal process, or as we believe is necessary to
          exercise our legal rights or defend against legal claims.
        </li>
      </ul>
      <p>
        In addition, we may share aggregate or de-identified information with
        third parties for marketing, advertising, research, and/or other lawful
        purposes.
      </p>
      <p>&nbsp;</p>
      <h2 id="cookies-and-similar-technologies">
        <strong>COOKIES AND SIMILAR TECHNOLOGIES</strong>
      </h2>
      <p>
        The Platform uses cookies. A &ldquo;cookie&rdquo; is a small text file
        that is placed on your computer or mobile device when you enter a
        website or a mobile app. It enables the website or mobile app to
        remember your actions and preferences over a period of time. While the
        cookies we use on the Platform may change from time to time, they
        generally fall into one of the following categories:
      </p>
      <ul>
        <li>
          <strong>Strictly necessary</strong>
          <strong>cookies</strong> &ndash; These cookies are strictly necessary
          in order for you to use the Platform, load and view content, and
          access your account.
        </li>
      </ul>
      <ul>
        <li>
          <strong>Functionality cookies </strong>&ndash; These cookies help us
          remember you and personalize features and content for you on the
          Platform.
        </li>
      </ul>
      <ul>
        <li>
          <strong>Preference cookies</strong> &ndash; These cookies allow us to
          capture and store your information privacy related preferences (e.g.,
          your opt-in and opt-out), account settings, and certain login
          information.
        </li>
      </ul>
      <ul>
        <li>
          <strong>Analytics cookies</strong> &ndash;These cookies help us and
          our service providers compile statistics and analytics about Platform
          users, including traffic, usage, demographic, and trend data. For
          example, we use Google Analytics to help us understand how users
          engage with the Platform. Google Analytics uses cookies to track your
          interactions with the Platform, then collects that information and
          reports it to us. This information helps us improve the Platform so
          that we can better serve you. For more information about Google
          Analytics, visit{' '}
          <a href="https://support.google.com/analytics/answer/6004245?hl=en">
            https://support.google.com/analytics/answer/6004245?hl=en
          </a>
          . If you wish, you can opt out of Google Analytics by installing the{' '}
          <a href="https://tools.google.com/dlpage/gaoptout">
            Google Analytics Opt-Out Browser Add-on
          </a>
          , available at{' '}
          <u>
            <a href="https://tools.google.com/dlpage/gaoptout">
              https://tools.google.com/dlpage/gaoptout
            </a>
          </u>
          .
        </li>
      </ul>
      <ul>
        <li>
          <strong>Advertising cookies</strong> &ndash; Third-party advertisers
          may use cookies, pixels, tags, etc. on the Platform to record
          information about your interactions with their advertisements. In
          addition, third-party ad networks and mediation platforms may use
          cookies, pixels, tags, etc. on the Platform to track and collect
          information about your online and in-app activities in order to target
          and tailor advertising to your interests.
        </li>
      </ul>
      <p>
        We and/or third parties may also use other industry standard
        technologies such as pixel tags and web beacons, to track and collect
        information about your interactions with the Platform, including your
        interactions with advertisements displayed on the Platform. Pixel tags
        and web beacons are tiny graphic images placed in websites and mobile
        apps, and they also can be placed in emails. The information collected
        through these technologies help us measure user interactions and improve
        our understanding of user behaviors.
      </p>
      <p>
        To learn more about cookies and similar technologies, please visit{' '}
        <a href="http://www.allaboutcookies.org">www.allaboutcookies.org</a>.
      </p>
      <p>
        <strong>Do-Not-Track Disclosure:</strong> Some web browsers incorporate
        a &ldquo;Do Not Track&rdquo; feature (&ldquo;DNT&rdquo;) that signals to
        the websites that you visit that you do not want to have your activity
        tracked. Presently, many websites and mobile apps, including the
        Platform, do not respond to DNT signals because such signals are not yet
        uniform. As a result, we and third parties may track your online
        activities, including over time and across different sites and apps.
      </p>
      <p>&nbsp;</p>
      <h2>
        <strong>DATA RETENTION &amp; DISPOSAL</strong>
      </h2>
      <p>
        We retain personal information for as long as it is necessary to fulfill
        the purposes for which we collected it, for the purposes of satisfying
        any legal, accounting, and/or reporting requirements, or as required or
        permitted by applicable law. To determine the appropriate retention
        period for personal information, we consider the amount, nature, and
        sensitivity of the personal information, the potential risk of harm from
        unauthorized use or disclosure of your personal information, the
        purposes for which we process your personal information, and the
        applicable legal requirements. In some circumstances we may anonymize
        (de-identify) your personal information (so that it can no longer be
        linked to or associated with you) for analytics, research, or other
        business purposes, in which case we may use and retain such anonymized
        information indefinitely without further notice to you.
      </p>
      <p>
        When we dispose of personal information, we aim to use secure means,
        such as either physically or electronically erasing the personal
        information or making it anonymous in a non-recoverable manner.
      </p>
      <p>&nbsp;</p>
      <h2>
        <strong>SECURITY</strong>
      </h2>
      <p>
        We implement reasonable security measures and controls to protect the
        confidentiality, integrity, and availability of the personal information
        we collect. However, no transmission of information over the Internet
        can be guaranteed to be 100% secure. We recommend that you use caution
        when sharing your personal information with us electronically,
        especially if such information is sensitive or confidential.
      </p>
      <p>&nbsp;</p>
      <h2>
        <strong>YOUR RIGHTS &amp; CHOICES</strong>
      </h2>
      <ul>
        <li>
          <strong>Information update:</strong> You can request to update your
          Contact Information maintained by GreenPark. To make such a request,
          please contact us using the information in the{' '}
          <strong>
            <u>
              <a href="#contact-us">CONTACT US</a>
            </u>
          </strong>{' '}
          section below. We reserve the right to verify your identity prior to
          honoring your request.
        </li>
      </ul>
      <ul>
        <li>
          <strong>Deletion of information: </strong>You can request to have your
          Contact Information deleted from GreenPark&rsquo;s database,{' '}
          <em>provided</em> that you must deactivate your account on the
          Platform and permanently cease using the Platform, including
          uninstalling and deleting our mobile apps completely from all your
          devices. To make such a request, please contact us using the
          information in the{' '}
          <strong>
            <u>
              <a href="#contact-us">CONTACT US</a>
            </u>
          </strong>{' '}
          section below. We reserve the right to verify your identity prior to
          honoring your request.
        </li>
      </ul>
      <ul>
        <li>
          <strong>
            Opting out of marketing communications from GreenPark:{' '}
          </strong>
          To stop receiving marketing emails from GreenPark, please click the
          &ldquo;unsubscribe&rdquo; link included in such an email you already
          received from us, and follow the on-screen instructions. To stop
          receiving marketing messages via SMS, please reply &ldquo;STOP&rdquo;
          to the number provided in such a message you already received from us.
          Alternatively, to make an opt-out request for email and SMS marketing
          communications from GreenPark, you can contact us using the
          information in the{' '}
          <strong>
            <u>
              <a href="#contact-us">CONTACT US</a>
            </u>
          </strong>{' '}
          section below.
        </li>
      </ul>
      <p>
        <strong>
          <u>NOTE</u>
        </strong>
        <strong>:</strong>
        <em>
          Your opt-out affects only marketing communications from GreenPark
          (i.e., communications that offer you or advertise products or services
          which you have not signed up for), and you will continue to receive
          communications from GreenPark that are transactional, administrative
          or relationship in nature, such as notices about your account or
          transactions you&rsquo;ve made, and notices about changes and updates
          to the Platform or to our Terms of Service or this Privacy Policy or
          our other terms and policies pertaining to the Platform. Furthermore,
          to the extent you receive marketing communications from our business
          partners, you must contact our business partners directly in order to
          opt out of their marketing communications
        </em>
        .
      </p>
      <ul>
        <li>
          <strong>Opting out of interest-based advertising: </strong>Some
          advertisers and marketing companies are participants in the
          self-regulatory programs of Network Advertising Initiative
          (&ldquo;NAI&rdquo;) and/or Digital Advertising Alliance
          (&ldquo;DAA&rdquo;) concerning online interest-based advertising. NAI
          and DAA provide consumers with the ability to opt out of
          interest-based advertising from their program participants at the
          following links:
        </li>
      </ul>
      <p>
        <a href="http://optout.networkadvertising.org/?c=1">
          http://optout.networkadvertising.org/?c=1
        </a>
      </p>
      <p>
        <a href="https://optout.aboutads.info/?c=2&amp;lang=EN">
          https://optout.aboutads.info/?c=2&amp;lang=EN
        </a>
      </p>
      <p>
        <a href="https://youradchoices.com/appchoices">
          https://youradchoices.com/appchoices
        </a>
      </p>
      <p>
        Most mobile devices have settings that will allow you to opt out of
        interest-based ads and limit ad tracking in your mobile apps. Please go
        to your device settings to learn more.
      </p>
      <p>
        <strong>
          <u>NOTE</u>
        </strong>
        <strong>: </strong>
        <em>
          Opting out of interest-based advertising means only that you will stop
          seeing certain advertisements tailored to your interests. You will,
          however, continue to see generic or contextual advertisements on the
          Platform
        </em>
        .
      </p>
      <p>&nbsp;</p>
      <h2>
        <strong>NOTICE TO CALIFORNIA RESIDENTS</strong>
      </h2>
      <p>
        California residents may request certain information regarding our
        sharing of personal information with third parties for such third
        parties&rsquo; direct marketing purposes. To make such a request, please
        contact us using the information in the{' '}
        <strong>
          <u>
            <a href="#contact-us">CONTACT US</a>
          </u>
        </strong>{' '}
        section below. This request may be made no more than once per calendar
        year, and we reserve our right not to respond to requests submitted
        other than to the email or mailing address specified in the{' '}
        <strong>
          <u>
            <a href="#contact-us">CONTACT US</a>
          </u>
        </strong>{' '}
        section below.
      </p>
      <p>&nbsp;</p>
      <h2>
        <strong>NOTICE TO INTERNATIONAL USERS</strong>
      </h2>
      <p>
        We are committed to complying with this Privacy Policy and the data
        protection laws that apply to our collection and use of your personal
        information. We and the Platform are located in the United States of
        America, where the laws may be different and, in some cases, less
        protective than the laws of other countries. By providing us with your
        personal information and using the Platform, you acknowledge that your
        personal information will be transferred to and processed in the United
        States.
      </p>
      <p>&nbsp;</p>
      <h2>
        <strong>CHILDREN'S PRIVACY</strong>
      </h2>
      <p>
        The Platform is not intended for or directed to anyone under 13 years of
        age, and certain features of the Platform may be further restricted to
        older users (e.g., 18 or older). Please do not use the Platform if you
        are under 13 years old.
      </p>
      <p>&nbsp;</p>
      <h2>
        <strong>LINKED THIRD-PARTY WEBSITES AND APPLICATIONS</strong>
      </h2>
      <p>
        The Platform may provide hyperlinks that, when clicked, take you to a
        third-party website that we do not own or control. Third parties&rsquo;
        websites or emails may also link to the Platform. These hyperlinked
        third-party websites may collect your personal information. We are not
        responsible for the collection, use, or disclosure of information
        collected through these websites, nor are we responsible for the content
        or the privacy practices employed by third parties.
      </p>
      <p>&nbsp;</p>
      <h2>
        <strong>CHANGES TO PRIVACY POLICY</strong>
      </h2>
      <p>
        We may update this Privacy Policy to reflect new or different ways that
        we collect, use and share your personal information, or to comply with
        law. If we make material modifications, we will make efforts to notify
        you in advance, such as by email or by posting notice in the Platform.
        Unless we state otherwise, if you continue to use the Platform after
        notice of such changes, this Privacy Policy as updated will apply to
        you.
      </p>
      <p>&nbsp;</p>
      <h2 id="contact-us">
        <strong>CONTACT US</strong>
      </h2>
      <p>
        If you have any questions about this Privacy Policy or our data
        practices, please contact us at:
      </p>
      <p>
        GreenPark Sports, Inc.
        <br /> ATTN.: Data Privacy
      </p>
      <p>1290 Howard Avenue, Suite 320</p>
      <p>Burlingame, California 94010</p>
      <p>
        <a href="mailto:privacy@greenparksports.com">
          privacy@greenparksports.com
        </a>
      </p>
    </div>
  </InfoPage>
);

export default PrivacyPolicy;
