import React from 'react';
import FacebookLogo from '../assets/images/facebook-logo.png';
import InstaLogo from '../assets/images/insta-logo.png';
import TwitterLogo from '../assets/images/twitter-logo.png';
import Logo from './Logo';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="footer-col col col-lg-2">
            <div className="footer-link-list">
              <p className="footer-list-title">Company</p>
              {/* 
              <a
                href="https://blog.greenparksports.com"
                target="_blank"
                className="footer-list-item"
              >
                About Us
              </a>
              */}
              <a
                href="mailto:partnerships@greenparksports.com"
                className="footer-list-item"
              >
                Sponsorships
              </a>
              <a
                href="https://boards.greenhouse.io/greenparksports"
                target="_blank"
                className="footer-list-item"
              >
                Careers
              </a>
              <a
                href="https://blog.greenparksports.com"
                target="_blank"
                className="footer-list-item"
              >
                Blog
              </a>
              <a
                href="mailto:support@greenparksports.com"
                className="footer-list-item"
              >
                Contact Us
              </a>
            </div>
            <div className="social">
              <div className="social-list">
                <a
                  href="https://twitter.com/greenparksports"
                  target="_blank"
                  className="social-item"
                >
                  <img src={TwitterLogo} alt="Twitter Logo" />
                </a>
                <a
                  href="https://www.instagram.com/greenparksports/"
                  target="_blank"
                  className="social-item"
                >
                  <img src={InstaLogo} alt="Instagram Logo" />
                </a>
                <a
                  href="https://www.facebook.com/greenparksports/"
                  target="_blank"
                  className="social-item"
                >
                  <img src={FacebookLogo} alt="Facebook Logo" />
                </a>
              </div>
            </div>
          </div>
          <div className="footer-col col col-lg-2">
            <div className="footer-link-list">
              <p className="footer-list-title">Privacy</p>
              {/* 
              <a href="/terms" className="footer-list-item">
                Legal
              </a>
            */}
              <a href="/terms" className="footer-list-item">
                Terms of Use
              </a>
              <a href="/nftterms" className="footer-list-item">
                NFT Terms
              </a>
              <a href="/privacy" className="footer-list-item">
                Privacy Policy
              </a>
            </div>
            <p className="copyright">
              © 2020 GreenPark Sports, Inc. All Rights Reserved
            </p>
          </div>
          <div className="footer-col col col-lg-3">
            <div className="footer-link-list">
              <p className="footer-list-title">DATA &amp; ANALYTICS PARTNERS</p>
              <a
                href="https://www.sportradar.com/"
                className="footer-list-item"
              >
                Sportradar
              </a>
              <a href="https://www.datarobot.com/" className="footer-list-item">
                DataRobot
              </a>
            </div>
          </div>
          <div className="footer-col footer-logo-column col col-12 col-lg-4 offset-lg-1">
            <div className="footer-logo">
              <a href="/">
                <Logo />
              </a>
              <p className="logo-taunt">May The Best Fans Win! &trade;</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
