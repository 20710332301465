import React, {FunctionComponent} from 'react';
import {ButtonLink} from './ButtonLink';

interface TitleTextProps {
  title?: string;
  titleColor?: string;
  text?: string;
  target?: string;
  ctaCopy?: string;
  ctaUrl?: string;
  ctaClass?: string;
}

const Logo: FunctionComponent<TitleTextProps> = ({
  title,
  titleColor,
  text,
  target,
  ctaCopy,
  ctaUrl,
  ctaClass,
}) => {
  const hasCta = ctaCopy && ctaUrl;
  let button;
  if (hasCta) {
    button = (
      <ButtonLink ctaUrl={ctaUrl} target={target} ctaClass={ctaClass}>
        {ctaCopy}
      </ButtonLink>
    );
  }
  return (
    <div className="title-text-section">
      <h2 className={'section-title ' + (titleColor ? titleColor : '')}>
        {title}
      </h2>
      <p className="section-text">{text}</p>
      {button}
    </div>
  );
};

export default Logo;
