import React from 'react';
import InfoPage from './InfoPage';

const NftTerms = () => (
  <InfoPage title="NFT TERMS">
    <div className="subpage-main-content">
      <h1>NFT TERMS</h1>
      <h2>
        <strong>Last Updated: November 12, 2021</strong>
      </h2>
      <p>
        The “Terms and Conditions” set forth below (collectively, these{' '}
        <em>“NFT Terms”</em>) apply to any NFT (defined below) that:
      </p>
      <ul>
        <li>
          you receive from GreenPark, whether through purchase, or as a reward,
          prize or gift given by GreenPark, or otherwise;
        </li>
        <li>
          you receive from a third party (e.g., when you acquire an NFT from the
          previous owner of that NFT on the secondary market); or
        </li>
        <li>
          you receive by converting (“minting”) a Virtual Good item you already
          have into an NFT (if GreenPark allows you to do so).
        </li>
      </ul>
      <p>
        As a recipient of an NFT (THROUGH PURCHASE OR OTHERWISE), you accept and
        agree to be bound by THESE NFT TERMS AS WELL AS OUR GENERAL
        <a href="https://greenparksports.com/terms">Terms Of Use</a>
        AND <a href="https://greenparksports.com/privacy">
          PRIVACY POLICY
        </a>{' '}
        WHICH ARE INCORPORATED HEREIN BY REFERENCE.
      </p>
      <p>&nbsp;</p>
      <p>As used herein:</p>
      <ul>
        <li>
          <em>"Epics"</em>&nbsp; means Epics Digital Collectibles, Inc., a
          Delaware corporation that operates the website https://app.epics.gg/.
          The GreenPark NFT Marketplace (defined below) is hosted by Epics
          pursuant to a service contract with GreenPark. Accordingly, for the
          purposes of our general{' '}
          <a href="https://greenparksports.com/terms">Terms Of Use</a> and these
          NFT Terms, Epics shall be deemed a Supplier of GreenPark.
        </li>
        <li>
          <em>"GreenPark"</em> or <em>"we"</em>&nbsp; means GreenPark Sports,
          Inc., a Delaware corporation that operates the website
          https://greenparksports.com/.
        </li>
        <li>
          <em>“GreenPark NFT Marketplace”</em>&nbsp; means the GreenPark website
          located at nft.greenparksports.com, which operates as GreenPark’s
          online destination for public release of NFTs.
        </li>
        <li>
          <em>"NFT”</em>&nbsp; means a digital collectible item released by (or
          on behalf of) GreenPark, the ownership of which item is represented by
          a blockchain-based non-fungible token. For clarity, all NFTs
          constitute Virtual Goods (as defined in our general{' '}
          <a href="https://greenparksports.com/terms">Terms Of Use</a>).
        </li>
        <li>
          <em>"Privacy Policy"</em>&nbsp; means GreenPark’s privacy policy
          located at https://greenparksports.com/privacy.
        </li>
        <li>
          <em>"Terms of Use"</em>&nbsp; means GreenPark’s general terms of use
          located at https://greenparksports.com/terms.
        </li>
        <li>
          <em>"Wallet"</em>&nbsp; means a digital wallet provided and maintained
          by a third-party wallet operator (e.g., Metamask), which allows the
          holder or an authorized user of the wallet to send, receive, and store
          cryptocurrencies and other blockchain-based assets.
        </li>
      </ul>
      <p>
        Other capitalized terms used but not otherwise defined herein have the
        meanings ascribed to them in our general{' '}
        <a href="https://greenparksports.com/terms">Terms Of Use</a>.
      </p>
      <p>&nbsp;</p>
      <h1>TERMS AND CONDITIONS: </h1>
      <p>
        As a recipient of an NFT (through purchase or otherwise), you
        understand, acknowledge and agree to ALL OF the following TERMS AND
        CONDITIONS:
      </p>
      <p>
        (1){' '}
        <strong>
          <u>No Warranty of Value; Assumption of Risk</u>:
        </strong>{' '}
        Collectible blockchain-based assets have no inherent or intrinsic value
        and their prices can be extremely volatile, unpredictable, and
        subjective. GREENPARK AND OUR AFFILIATES AND SUPPLIERS DO{' '}
        <em>
          <strong>NOT</strong>
        </em>{' '}
        REPRESENT OR WARRANT THAT AN NFT HAS ANY MONETARY VALUE, OR WILL
        INCREASE (OR DECREASE) IN VALUE OVER TIME, OR WILL MAINTAIN ANY VALUE,
        OR CAN BE SOLD, RESOLD, TRADED, LIQUIDATED OR EXCHANGED FOR ANY TYPE OF
        "REAL WORLD" MONEY OR ASSET. The initial selling price of an NFT listed
        in the GreenPark NFT Marketplace is set arbitrarily by GreenPark and
        does{' '}
        <strong>
          <em>not</em>
        </strong>{' '}
        represent, warrant, reflect, suggest or infer any current or future
        value of that NFT. Neither GreenPark nor any of our affiliates or
        Suppliers determines or controls the selling or trading price of an NFT
        on the secondary market, which price is set and controlled solely by the
        then-current owner of that NFT. YOU UNDERSTAND, ACKNOWLEDGE AND AGREE
        THAT IF YOU CHOOSE TO PURCHASE AN NFT, YOU ASSUME ANY AND ALL RISKS OF
        LOSS OF MONEY AND LOSS OF VALUE ASSOCIATED WITH THAT NFT.
      </p>
      <p>&nbsp;</p>
      <p>
        (2){' '}
        <strong>
          <u>Inherent Risks With Digital Assets</u>:
        </strong>{' '}
        There are risks associated with Internet-based digital assets, which
        include, but are not limited to, the risk of hardware, software and
        Internet connections, the risk of malicious software introduction, and
        the risk that third parties may obtain unauthorized access to
        information stored within your Wallet. YOU UNDERSTAND, ACKNOWLEDGE AND
        AGREE THAT GREENPARK AND OUR AFFILIATES AND SUPPLIERS WILL{' '}
        <em>
          <strong>NOT</strong>
        </em>{' '}
        BE RESPONSIBLE OR LIABLE TO YOU FOR ANY COMMUNICATION FAILURES,
        DISRUPTIONS, ERRORS, DISTORTIONS OR DELAYS YOU MAY EXPERIENCE WHEN USING
        THE GREENPARK NFT MARKETPLACE, HOWEVER CAUSED.
      </p>
      <p>&nbsp;</p>
      <p>
        (3){' '}
        <strong>
          <u>Inherent Risks with Third-Party Blockchain Network</u>:
        </strong>{' '}
        All NFT transactions (purchase, sale, trading, transfer, etc.) are
        dependent upon the applicable third-party blockchain network (e.g.,
        Ethereum). Smart contracts and third-party blockchain network
        technologies are still in an early development stage and unproven.
        Changes and updates to the applicable third-party blockchain network, a
        hard fork in the applicable third-party blockchain network, or a change
        in how transactions are confirmed on the applicable third-party
        blockchain network, may materially adversely affect the GreenPark NFT
        Marketplace and the potential value or utility of NFTs.
      </p>
      <p>&nbsp;</p>
      <p>
        (4){' '}
        <strong>
          <u>Risks of Theft and Loss</u>:
        </strong>{' '}
        Please be aware that hacks, cyber-attacks, distributed denials of
        service or errors, double-spent attacks, flash-loan attacks,
        vulnerabilities, defects or flaws in the applicable third-party
        blockchain network, or other events that are beyond our control may lead
        to partial or complete theft or loss of NFTs.
      </p>
      <p>&nbsp;</p>
      <p>
        (5){' '}
        <strong>
          <u>Regulatory Uncertainty</u>:
        </strong>{' '}
        The legal and regulatory regime governing blockchain technologies,
        cryptocurrencies, NFTs, etc. is uncertain, and new regulations or
        policies may materially adversely affect the GreenPark NFT Marketplace
        and the potential value or utility of NFTs.
      </p>
      <p>&nbsp;</p>
      <p>
        (6){' '}
        <strong>
          <u>Not An Investment or Security</u>:
        </strong>{' '}
        AN NFT IS{' '}
        <em>
          <strong>NOT</strong>
        </em>{' '}
        AN INVESTMENT, SECURITY, SHARE OF STOCK OR EQUITY INTEREST, DEBT OR
        LOAN, NOR A DERIVATIVE INSTRUMENT OF ANY OF THE FOREGOING. Nothing in
        the GreenPark NFT Marketplace or otherwise communicated or provided by
        GreenPark or any of our affiliates or Suppliers constitutes a prospectus
        or offering document, or is an offer to sell or solicitation of an offer
        to purchase an investment, security, share of stock or equity interest.
      </p>
      <p>&nbsp;</p>
      <p>
        (7){' '}
        <strong>
          <u>Use of Wallet</u>:
        </strong>{' '}
        In an NFT purchase transaction, the cryptocurrency assets used to pay
        for the NFT are transferred from the buyer/recipient’s Wallet to the
        seller’s (or its agent’s) Wallet, and the NFT is then transferred from
        the seller’s (or its agent’s) Wallet to the buyer/recipient’s Wallet.
        So, in order to acquire an NFT on the GreenPark NFT Marketplace, you
        must have a Wallet that is accepted by GreenPark, and must connect such
        Wallet to your account on the GreenPark NFT Marketplace. By using a
        Wallet to acquire an NFT, you represent and warrant that you are the
        lawful holder or an authorized user of such Wallet and that you are in
        compliance with all of the terms, rules and policies of the third-party
        wallet operator in connection with your use of such Wallet. Acceptable
        Wallets and acceptable types of cryptocurrency for purchase of NFTs on
        the GreenPark NFT Marketplace are subject to change at any time in
        GreenPark’s sole discretion.
      </p>
      <p>
        The operation and security of your Wallet is entirely the responsibility
        of the applicable third-party wallet operator (e.g., Metamask). YOU
        UNDERSTAND, ACKNOWLEDGE AND AGREE THAT GREENPARK AND OUR AFFILIATES AND
        SUPPLIERS WILL{' '}
        <em>
          <strong>NOT</strong>
        </em>{' '}
        BE RESPONSIBLE OR LIABLE TO YOU FOR ANY DEFECT, ERROR, MALFUNCTION,
        FAILURE, OR BREACH OF SECURITY OF YOUR WALLET, OR FOR ANY DAMAGES OR
        LOSSES (INCLUDING ANY LOSS OF NFT, CRYPTOCURRENCY, OR OTHER DIGITAL
        ASSETS) YOU MAY INCUR AS A RESULT.
      </p>
      <p>&nbsp;</p>
      <p>
        (8){' '}
        <strong>
          <u>Payment for A Purchase</u>:
        </strong>{' '}
        By purchasing an NFT, you agree to pay the selling price in the type of
        cryptocurrency as listed at the point of sale for that NFT, including
        any applicable transaction fees (“gas fees”), and your obligation to pay
        becomes final and binding the moment you submit an order for that NFT.
        You may not substitute any other currency, whether cryptocurrency or
        fiat currency, for the currency you have agreed to pay at the time of
        purchase. No fluctuation in the value of any currency, whether
        cryptocurrency or otherwise, will impact or excuse your obligation to
        pay in connection with a purchase.
      </p>
      <p>&nbsp;</p>
      <p>
        (9){' '}
        <strong>
          <u>Use of Third-Party Blockchain Network</u>:
        </strong>{' '}
        GREENPARK AND OUR AFFILIATES AND SUPPLIERS DO{' '}
        <em>
          <strong>NOT</strong>
        </em>{' '}
        SEND, RECEIVE, OR STORE NFTS OR CRYPTOCURRENCIES ON THE GREENPARK
        SERVICES (INCLUDING THE GREENPARK NFT MARKETPLACE). Rather, NFTs and
        cryptocurrency assets exist only by virtue of their ownership records
        maintained on the applicable third-party blockchain network (e.g.,
        Ethereum). This means that all transfers involving NFTs and
        cryptocurrency assets, including their associated smart contracts, are
        conducted and occur solely on the applicable third-party blockchain
        network. GREENPARK AND OUR AFFILIATES AND SUPPLIERS HAVE{' '}
        <em>
          <strong>NO</strong>
        </em>{' '}
        CONTROL OVER THE APPLICABLE THIRD-PARTY BLOCKCHAIN NETWORK, AND MAKE{' '}
        <em>
          <strong>NO</strong>
        </em>{' '}
        WARRANTY THAT ANY TRANSFER INVOLVING NFTS OR CRYPTOCURRENCY WILL BE
        EFFECTED ON APPLICABLE THIRD-PARTY BLOCKCHAIN NETWORK.
      </p>
      <p>&nbsp;</p>
      <p>
        (10){' '}
        <strong>
          <u>Purchase Not Reversable and Not Refundable</u>:
        </strong>{' '}
        Because any transfer of NFTs and cryptocurrency assets occurs solely on
        the applicable third-party blockchain network, GREENPARK AND OUR
        AFFILIATES AND SUPPLIERS DO{' '}
        <em>
          <strong>NOT</strong>
        </em>{' '}
        HAVE THE ABILITY TO CANCEL, REVERSE, OR REFUND AN NFT PURCHASE OR
        PAYMENT THEREFOR.
      </p>
      <p>&nbsp;</p>
      <p>
        (11){' '}
        <strong>
          <u>Taxes</u>:
        </strong>{' '}
        You are solely responsible for determining what taxes, if any, apply to
        your purchase, receipt, ownership, use, sale, resale, trading, transfer
        or other disposition of an NFT, and you are solely responsible for
        paying any and all such taxes. GreenPark and our affiliates and
        Suppliers are{' '}
        <em>
          <strong>not</strong>
        </em>{' '}
        responsible for determining or paying any taxes that may apply to your
        purchase, receipt, ownership, use, sale, trading, transfer or other
        disposition of an NFT.
      </p>
      <p>&nbsp;</p>
      <p>
        (12)
        <strong>
          {' '}
          <em>
            <u>Gas Fees</u>
          </em>
          :{' '}
        </strong>
        When you purchase or sell an NFT, the applicable third-party blockchain
        network (e.g., Ethereum) may charge “gas fees,” and you agree to pay
        such fees and authorize GreenPark and our affiliates and Suppliers to
        automatically deduct such fees from your payment.
      </p>
      <p>&nbsp;</p>
      <p>
        (13)
        <strong>
          {' '}
          <em>
            <u>No Transfer of Intellectual Property Rights in NFTs</u>
          </em>
          :{' '}
        </strong>
        When you acquire an NFT (through purchase or otherwise), you acquire
        possession of that NFT as your personal property and will have the right
        to sell, transfer, or otherwise dispose of that NFT.
      </p>
      <p>
        HOWEVER, YOU UNDERSTAND, ACKNOWLEDGE AND AGREE THAT: (I) AS A RECIPIENT
        OF AN NFT, YOU DO{' '}
        <em>
          <strong>NOT</strong>
        </em>{' '}
        ACQUIRE ANY TITLE OR OWNERSHIP IN ANY TRADEMARK, COPYRIGHT OR OTHER
        INTELLECTUAL PROPERTY OR PROPRIETARY RIGHTS IN ANY OF THE CONTENT OR
        MATERIAL COMPRISING THAT NFT; AND (II) GREENPARK AND/OR OUR AFFILIATES
        OR SUPPLIERS (AS APPLICABLE) RETAIN OWNERSHIP OF ALL INTELLECTUAL
        PROPERTY AND OTHER PROPRIETARY RIGHTS IN ALL OF THE CONTENT AND MATERIAL
        COMPRISING THAT NFT, AND THERE IS{' '}
        <em>
          <strong>NO</strong>
        </em>{' '}
        SALE OR TRANSFER OF ANY SUCH INTELLECTUAL PROPERTY OR OTHER PROPRIETARY
        RIGHTS FROM GREENPARK OR OUR AFFILIATES OR SUPPLIERS (AS APPLICABLE) TO
        YOU IN CONNECTION WITH YOUR ACQUIRING OF THAT NFT (THROUGH PURCHASE OR
        OTHERWISE).
      </p>
      <p>
        For clarity, the content or material comprising an NFT (the{' '}
        <em>“Content”</em> of an NFT) may include any of the following: (i) the
        names, brands, marks, logos, and/or other identifiers of GreenPark or
        our affiliates or Suppliers (as applicable); (ii) the names, likenesses,
        voices, autographs, and/or other elements of identity of real persons
        (e.g., athletes); and (iii) original works of authorship owned by
        GreenPark and/or our Suppliers, such as text, artwork, designs, photos,
        images, graphics, animations, audio material (such as music, sound
        effects, etc.), and video material.
      </p>
      <p>
        GreenPark and our affiliates and Suppliers grant to you, as a recipient
        of an NFT, a non-exclusive, worldwide, royalty-free, limited and
        revocable license to use and display the Content of such NFT (without
        alteration or modification), solely for the following purposes: (i) for
        your own personal, non-commercial uses; and (ii) in connection with your
        sale, transfer, trading, or other disposition of such NFT consistent
        with your ownership thereof.
      </p>
      <p>&nbsp;</p>
      <p>
        (14)
        <strong>
          {' '}
          <em>
            <u>Commercial Use of NFTs Prohibited</u>
          </em>
          :{' '}
        </strong>
        You may not (or cause or permit any third party to) make commercial use
        of any of the Content of an NFT, or any content or material derived
        therefrom, without the express prior written authorization of GreenPark
        or our affiliates or Suppliers (as applicable). Without limiting the
        generality of the preceding sentence, you may not (or cause or permit
        any third party to):
      </p>
      <ul>
        <li>
          reproduce, use or display any of the Content of an NFT, or any content
          or material derived therefrom, in connection with advertising,
          promotion, marketing, or sale of any third-party product or service;
        </li>
        <li>
          reproduce, use or display any of the Content of an NFT, or any content
          or material derived therefrom, in/on any merchandise, collectible or
          memorabilia (digital or non-digital) that is intended for sale or
          commercial distribution; or
        </li>
        <li>
          reproduce, use or display any of the Content of an NFT, or any content
          or material derived therefrom, in any film, television, video, game,
          or other media production of a commercial nature.
        </li>
      </ul>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        (15)
        <strong>
          {' '}
          <em>
            <u>Other Prohibited Activities</u>
          </em>
          :{' '}
        </strong>
        You may{' '}
        <em>
          <strong>not</strong>
        </em>
        :
      </p>
      <ul>
        <li>
          use a Wallet that you neither own nor have authorization to use;
        </li>
        <li>
          use a Wallet in violation of any applicable law or any of the terms,
          rules or policies of the applicable third-party wallet operator;
        </li>
        <li>
          sell or attempt to sell any digital collectible that is fake or
          forgery, or that infringes upon or violates any intellectual property
          or other proprietary rights of GreenPark or any of our affiliates or
          Suppliers, or violates any applicable law;
        </li>
        <li>
          use the GreenPark NFT Marketplace to carry out any illegal activities,
          including, without limitation, money laundering, terrorist financing,
          fraud/scams/deceptive trading, and activities designed to disrupt the
          operation of the GreenPark NFT Marketplace or another’s access and use
          of the GreenPark NFT Marketplace;
        </li>
        <li>
          engage in or knowingly facilitate any “front-running,” “wash trading,”
          “pump and dump trading,” “ramping,” “cornering” or fraudulent,
          deceptive or manipulative trading activities, including:
        </li>
        <ul>
          <li>
            trading an NFT at successively lower or higher prices for the
            purpose of creating or inducing a false, misleading or artificial
            appearance of activity in such NFT, or unfairly or deceptively
            influencing the market price of an NFT;
          </li>
          <li>
            for the purpose of creating or inducing a false or misleading
            appearance of activity in an NFT or creating or inducing a false or
            misleading appearance with respect to the market for an NFT: (A)
            executing or causing the execution of any transaction in an NFT
            which involves no material change in the beneficial ownership
            thereof; or (B) entering any order for the purchase or sale of an
            NFT with the knowledge that an order of substantially the same size,
            and at substantially the same price, for the sale of such NFT, has
            been or will be entered by or for the same or different parties; or
          </li>
          <li>
            participating in, facilitating, assisting or knowingly transacting
            with any pool, syndicate or joint account organized for the purpose
            of unfairly or deceptively influencing the market price of an NFT.
          </li>
        </ul>
      </ul>
      <p>&nbsp;</p>
      <p>
        (16)
        <strong>
          {' '}
          <em>
            <u>As Determined by GreenPark</u>
          </em>
          :{' '}
        </strong>
        For any NFT that we decide to release to the public, we determine, in
        our sole discretion, the form, quantity, price, composition, and
        characteristics of such NFT. If we release NFTs as a collection, series,
        set or group (<em>“Set”</em>), we may, in our sole discretion, reserve
        any number of NFTs from a given Set, and we are under no obligation to
        make available all NFTs from any given Set. We will disclose in
        connection with the sale of any Set of NFTs if a portion of such Set has
        been reserved.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        (17)
        <strong>
          {' '}
          <em>
            <u>Reservation of Rights</u>
          </em>
          :{' '}
        </strong>
        We reserve the right to suspend access to any NFT if we determine in our
        sole discretion that such NFT or your receipt, ownership, use or
        disposition thereof may be in violation of any applicable law, or of
        these NFT Terms, or of our general{' '}
        <a href="https://greenparksports.com/terms">Terms of Use</a>. An NFT you
        acquire (through purchase or otherwise) may become inaccessible or
        unviewable, and under no circumstances will your inability to access or
        view an NFT serve as grounds for a claim against GreenPark or any of our
        affiliates or Suppliers.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        (18)
        <strong>
          {' '}
          <em>
            <u>DISCLAIMERS</u>
          </em>
          :{' '}
        </strong>
        THE GREENPARK NFT MARKETPLACE AND ALL NFTS ARE PROVIDED ON AN “AS IS”
        AND “AS AVAILABLE” BASIS, WITHOUT WARRANTIES OF ANY KIND, WHETHER
        EXPRESS OR IMPLIED.
      </p>
      <p>
        TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, GREENPARK AND
        OUR AFFILIATES AND SUPPLIERS DISCLAIM ANY AND ALL WARRANTIES, EXPRESS OR
        IMPLIED, WITH RESPECT TO THE GREENPARK NFT MARKETPLACE AND ALL NFTS,
        INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY AND
        FITNESS FOR A PARTICULAR PURPOSE, AND WARRANTIES OF TITLE AND
        NON-INFRINGEMENT.
      </p>
      <p>
        WITHOUT LIMITATION TO THE GENERALITY OF THE FOREGOING, GREENPARK AND OUR
        AFFILIATES AND SUPPLIERS DO{' '}
        <strong>
          <em>NOT</em>
        </strong>{' '}
        WARRANT THAT: (i) THE GREENPARK NFT MARKETPLACE AND ALL NFTS WILL BE
        SECURE, RELIABLE, OR ERROR-FREE, OR WILL BE UNINTERRUPTED, OR WILL BE
        AVAILABLE AT ANY PARTICULAR TIME OR LOCATION; (ii) ANY DEFECT OR ERROR
        IN THE GREENPARK NFT MARKETPLACE OR IN ANY NFT WILL BE CORRECTED; OR
        (iii) THE GREENPARK NFT MARKETPLACE AND ALL NFTS WILL BE FREE OF VIRUSES
        OR OTHER HARMFUL COMPONENTS.
      </p>
      <p>
        SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES IN
        CONTRACTS WITH CONSUMERS, SO SOME OR ALL OF THE ABOVE EXCLUSIONS MAY NOT
        APPLY TO YOU.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        (19)
        <strong>
          {' '}
          <em>
            <u>Limitation of Liability for NFT Related Losses</u>
          </em>
          :{' '}
        </strong>
        YOU UNDERSTAND, ACKNOWLEDGE AND AGREE THAT GREENPARK AND OUR AFFILIATES
        AND SUPPLIERS WILL{' '}
        <strong>
          <em>NOT</em>
        </strong>{' '}
        BE LIABLE TO YOU FOR ANY LOSSES, DAMAGES AND/OR CLAIMS ARISING OUT OF OR
        RELATED TO YOUR USE OF (OR YOUR INABILITY TO USE) THE GREENPARK NFT
        MARKETPLACE, OR YOUR PURCHASE, RECEIPT, USE, SALE, TRADING, TRANSFER OR
        OTHER DISPOSITION OF ANY NFT, OR YOUR INABILITY TO PURCHASE, RECEIVE,
        USE, SELL, TRADE, TRANSFR OR OTHERWISE DISPOSE OF ANY NFT (COLLECTIVELY,
        <em>“NFT RELATED LOSSES”</em>).
      </p>
      <p>
        WITHOUT LIMITATION TO THE GENERALITY OF THE FOREGOING, GREENPARK AND OUR
        AFFILIATES AND SUPPLIERS WILL{' '}
        <strong>
          <em>NOT</em>
        </strong>{' '}
        BE LIABLE TO YOU FOR ANY NFT RELATED LOSSES THAT RESULT FROM ANY OF THE
        FOLLOWING:
      </p>
      <ul>
        <li>
          USER ERROR, SUCH AS AN INADVERTENT PURCHASE OR A PURCHASE MADE IN
          ERROR OR BY MISTAKE, FORGOTTEN PASSWORDS, OR INCORRECTLY CONSTRUCTED
          SMART CONTRACTS OR TRANSACTIONS;
        </li>
        <li>
          INTERNET CONNECTION ISSUES, SERVER ISSUES, HARDWARE OR SOFTWARE
          ISSUES, OR CORRUPTION OR LOSS OF DATA;
        </li>
        <li>
          ANY ISSUE WITH THE APPLICABLE THIRD-PARTY BLOCKCHAIN NETWORK,
          INCLUDING FORKS, TECHNICAL NODE ISSUES, OR ANY OTHER ISSUE RESULTING
          IN ASSET OR FUND LOSSES, AND INCLUDING ALSO LATE REPORT OR NO REPORT
          AT ALL OF ANY SUCH ISSUES BY DEVELOPERS OR REPRESENTATIVES;
        </li>
        <li>
          ANY ISSUE WITH A WALLET YOU USE, INCLUDING, WITHOUT LIMITATION, WALLET
          ERROR, MALFUNCTION OR FAILURE, OR CORRUPTED WALLET FILES; OR
        </li>
        <li>
          UNAUTHORIZED ACCESS OR ACTIVITIES BY THIRD PARTIES, INCLUDING, WITHOUT
          LIMITATION, THE USE OF VIRUSES, PHISHING, BRUTE-FORCING OR OTHER MEANS
          OF ATTACK AGAINST THE GREENPARK NFT MARKETPLACE, OR AGAINST THE
          APPLICABLE THIRD-PARTY BLOCKCHAIN NETWORK, OR AGAINST A WALLET.
        </li>
      </ul>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        (20)
        <strong>
          {' '}
          <em>
            <u>User Release and Indemnity</u>
          </em>
          :{' '}
        </strong>
        You agree to release and forever discharge (to the fullest extent
        permitted by applicable law), and further agree to indemnify and hold
        harmless, GreenPark and our affiliates and Suppliers, including our and
        their successors and assigns, from any and all NFT Related Losses.
      </p>
      <p>
        IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE
        SECTION 1542 IN CONNECTION WITH ANY AND ALL NFT RELATED LOSSES, WHICH
        SECTION STATES:{' '}
        <em>
          “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES
          NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF
          EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE
          MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.”
        </em>
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        (21)
        <strong>
          {' '}
          <em>
            <u>Changes to the Terms</u>
          </em>
          :{' '}
        </strong>
        We may revise and update these NFT Terms from time to time in our sole
        discretion. All changes are effective immediately when we post them, and
        will thereafter apply to all NFTs subject hereto. However, any changes
        to the dispute resolution provisions set forth in these NFT Terms will
        not apply to any disputes for which the parties have actual notice on or
        prior to the date the change is posted.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        (22)
        <strong>
          {' '}
          <em>
            <u>Third Party Beneficiaries</u>
          </em>
          :{' '}
        </strong>
        You agree that our affiliates and Suppliers, which include, without
        limitation, Epics, are intended third party beneficiaries of these NFT
        Terms, our general{' '}
        <a href="https://greenparksports.com/terms">Terms of Use</a> and our{' '}
        <a href="https://greenparksports.com/privacy">Privacy Policy</a> and all
        provisions referenced herein and therein. Each such party may enforce
        all such provisions against you in every way to the same extent as
        GreenPark.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        (23)
        <strong>
          {' '}
          <em>
            <u>Miscellaneous</u>
          </em>
          :{' '}
        </strong>
        All of the provisions contained in our general{' '}
        <a href="https://greenparksports.com/terms">Terms of Use</a>, including,
        without limitation, “
        <a href="https://greenparksports.com/terms#no-warranties">
          No Warranties; Limitation of Liability
        </a>
        ,” “
        <a href="https://greenparksports.com/terms#arbitration-agreement">
          Arbitration Agreement and Class Action Waiver
        </a>
        ,” “
        <a href="https://greenparksports.com/terms#time-limit">
          Time Limit On Filing Claims
        </a>
        ,” and “
        <a href="https://greenparksports.com/terms#governing-law">
          Governing Law; Jurisdiction
        </a>
        ,” also apply to you and are incorporated into these NFT Terms by
        reference.
      </p>
    </div>
  </InfoPage>
);

export default NftTerms;
