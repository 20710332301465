import React, {FunctionComponent} from 'react';
import GPLogo from '../assets/images/logo.png';

interface LogoProps {
  small?: boolean;
}

const Logo: FunctionComponent<LogoProps> = ({small}) => {
  return (
    <div className={'logo' + (small ? ' logo-small' : '')}>
      <img src={GPLogo} alt="GreenPark Sports Logo" />
    </div>
  );
};

export default Logo;
