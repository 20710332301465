import React from 'react';
import linkGreenie from '../assets/images/community-greenie-digitas.png';
import DiscordIcon from '../assets/images/discord-icon.png';
import InstagramIcon from '../assets/images/instagram-icon.png';
import TikTokIcon from '../assets/images/tiktok-icon.png';
import TwitterIcon from '../assets/images/twitter-icon.png';
import YoutubeIcon from '../assets/images/youtube-icon.png';

const Links = () => {
  return (
    <div className="links-section">
      <div className="container section-content">
        <div className="row align-items-center">
          <div className="col-xs-12 col-lg-5">
            <h2 className="section-title black community-headline">
              WE'RE BRINGING OUR 'A' GAME TO YOU
            </h2>
            <p className="section-text">
              Diehard fans need a place to scream online. We get it. So we’re
              building epic GreenPark fan communities across your favorite
              social media sites.
            </p>

            <div className="social-icons-wrapper">
              <a
                href="https://www.instagram.com/greenparksports/"
                target="_blank"
              >
                <img
                  src={InstagramIcon}
                  alt="Instagram Social Icon"
                  className="community-social-icon img-fluid"
                />
              </a>
              <a
                href="https://www.tiktok.com/@greenparksports?"
                target="_blank"
              >
                <img
                  src={TikTokIcon}
                  alt="TikTok Social Icon"
                  className="community-social-icon img-fluid"
                />
              </a>
              <a href="https://discord.gg/p5p2gPE" target="_blank">
                <img
                  src={DiscordIcon}
                  alt="Discord Social Icon"
                  className="community-social-icon img-fluid"
                />
              </a>
              <a href="https://www.youtube.com/c/GreenPark/" target="_blank">
                <img
                  src={YoutubeIcon}
                  alt="Youtube Social Icon"
                  className="community-social-icon img-fluid"
                />
              </a>
              <a href="https://twitter.com/greenparksports" target="_blank">
                <img
                  src={TwitterIcon}
                  alt="Twitter Social Icon"
                  className="community-social-icon img-fluid"
                />
              </a>
            </div>
          </div>
          <div className="col-xs-12 col-lg-7">
            <div className="section-image-wrapper">
              <img
                src={linkGreenie}
                alt="links greenie group"
                className="img-fluid section-image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Links;
