import React from 'react';
import InfoPage from './InfoPage';

const SuperFanOfficialRules = () => (
  <InfoPage title="The LaLiga SuperFan Sweepstakes">
    <div className="subpage-main-content">
      <h1>OFFICIAL RULES</h1>
      <h2>
        <strong>
          <em>
            NO PURCHASE OR PAYMENT OF ANY KIND IS NECESSARY TO ENTER OR WIN. A
            PURCHASE WILL NOT INCREASE CHANCES OF WINNING.
          </em>
        </strong>
      </h2>
      <p>&nbsp;</p>
      <ol>
        <li>
          SPONSOR: This Sweepstakes is sponsored by Greenpark Sports, Inc., with
          an address at 1290 Howard Avenue, Suite 320, Burlingame, California
          94010 (&ldquo;
          <em>
            <u>Sponsor</u>
          </em>
          &rdquo;).
          <p>&nbsp;</p>
          <p>
            <em>
              The Liga Nacional de F&uacute;tbol Profesional
              (&ldquo;LaLiga&rdquo;) and its participating football clubs, and
              Banco Santander, S.A.,{' '}
            </em>
            <em>
              are NOT sponsors of this Sweepstakes.&nbsp; The
              &ldquo;LaLiga&rdquo; name and logo are registered trademarks of
              LaLiga and are used by Sponsor under license.&nbsp; The names and
              logos of the participating football clubs of LaLiga are registered
              trademarks of the respective football clubs and are used by
              Sponsor under license.&nbsp; The &ldquo;Santander&rdquo; name and
              logo are registered trademarks of Banco Santander, S.A. and are
              used by Sponsor under license.
            </em>
          </p>
          <p>
            This Sweepstakes is conducted online at the website{' '}
            <a href="https://superfanstudio.greenparksports.com">http://superfanstudio.greenparksports.com</a> (the &ldquo;
            <em>
              <u>Website</u>
            </em>
            &rdquo;).&nbsp; Sponsor uses the services of Merci-Michel (the
            &ldquo;
            <em>
              <u>Designated Service Provider</u>
            </em>
            &rdquo;) for the operation of the Website.
          </p>
          <p>&nbsp;</p>
        </li>
        <li>
          ELIGIBILITY:
          <strong>
            This Sweepstakes is open only to legal residents of the United
            States (including Puerto Rico) and Canada (excluding Quebec)
            <em>at least 13 years of age</em> at the time of entry.
          </strong>{' '}
          Employees of Sponsor or the Designated Service Provider, as well as
          members of their immediate families or households, are NOT eligible to
          enter or win in this Sweepstakes. Void where prohibited or restricted
          by law.
        </li>
        <p>&nbsp;</p>
        <li>
          ENTRY PERIOD: The entry period for this Sweepstakes starts at{' '}
          <strong>
            5:00 AM (Pacific Daylight Saving Time) on May 5, 2021{' '}
          </strong>
          and ends at{' '}
          <strong>
            11:59 PM (Pacific Daylight Saving Time) on May 31, 2021
          </strong>{' '}
          (the &ldquo;
          <em>
            <u>Entry Period</u>
          </em>
          &rdquo;). Entries submitted before or after the Entry Period will NOT
          be eligible. The Designated Service Provider&rsquo;s computer for the
          Website is the official time-keeping device for this Sweepstakes.
        </li>
        <p>&nbsp;</p>
        <li>
          Agreement to Rules: By entering this Sweepstakes, you (i) agree to
          abide by these Official Rules and also agree to Sponsor&rsquo;s{' '}
          <u>
            <a href="/terms">Terms of Use</a>
          </u>{' '}
          and{' '}
          <u>
            <a href="/privacy">Privacy Policy</a>
          </u>{' '}
          as posted on the Website, (ii) represent and warrant that you meet all
          the eligibility requirements of this Sweepstakes, and (iii) accept all
          decisions of Sponsor as related to this Sweepstakes as final and
          binding. You will look solely to Sponsor for questions, comments and
          issues concerning this Sweepstakes.
        </li>
        <p>&nbsp;</p>
        <li>
          HOW TO ENTER: If you are eligible, you may enter this Sweepstakes in
          one of two ways:
          <p>&nbsp;</p>
          <ul>
            <li>
              <u>Enter Online</u>: Go to the Website and do the following:
              <p>&nbsp;</p>
              <ul>
                <li>Select your favorite LaLiga football club;</li>
                <li>
                  Use the &ldquo;Greenie&rdquo; superfan customization tools on
                  the Website to generate your version of the
                  &ldquo;Greenie&rdquo; superfan;
                </li>
                <li>Select your favorite LaLiga football club;</li>
                <li>
                  Use the &ldquo;Greenie&rdquo; superfan customization tools on
                  the Website to generate your version of the
                  &ldquo;Greenie&rdquo; superfan;
                </li>
                <li>
                  Create a GreenPark account on the Website (which will also be
                  your account for Sponsor&rsquo;s GreenPark app); and
                </li>
                <li>
                  Share your version of the &ldquo;Greenie&rdquo; superfan on
                  Facebook, Twitter and/or Instagram with your GreenPark account
                  username (#username) and #GPSuperFan as hashtags.
                </li>
              </ul>
            </li>
            <p>&nbsp;</p>
            <li>
              <u>Enter by Mail</u>: Print your full name, date of birth, email
              address, country and state/province of residence, and your
              favorite LaLiga football club, on a piece of paper and mail it to
              Greenpark Sports, Inc., 1290 Howard Avenue, Suite 320, Burlingame,
              California 94010, : LaLiga SuperFan Sweepstakes, within the Entry
              Period.{' '}
              <strong>
                A mail-in entry must be post-marked no later than May 31, 2021,
                or will be deemed void.
              </strong>
            </li>
          </ul>
          <p>&nbsp;</p>
          <p>
            This Sweepstakes is limited to the entry methods specified above,
            and no other method of entry will be accepted.&nbsp;{' '}
            <strong>
              Limit of one (1) entry per eligible entrant, regardless of the
              method of entry.
            </strong>
          </p>
          <p>&nbsp;</p>
          <p>
            An entry must meet all requirements of this Sweepstakes, as
            specified, to be eligible to win a prize. &nbsp;Entries that are
            incomplete or do not adhere to the rules of this Sweepstakes may be
            disqualified at the sole discretion of Sponsor. &nbsp;You must
            provide the information requested. You may NOT enter more times than
            indicated by using multiple email addresses, identities, or devices
            in an attempt to circumvent the rules. If you use fraudulent methods
            or otherwise attempt to circumvent the rules of this Sweepstakes,
            your submission may be disqualified at the sole discretion of
            Sponsor.&nbsp; Entries generated by script, macro or other automated
            means are void.&nbsp; Sponsor and the Designated Service Provider
            will NOT be responsible for lost, stolen, misplaced, misdirected,
            incomplete, or illegible entries.
          </p>
          <p>&nbsp;</p>
        </li>
        <li>
          PRIZES: Twenty (20) prizes will be awarded to twenty (20) winners in
          this Sweepstakes. The prize for each winner will be an official jersey
          of his/her favorite LaLiga football club (as selected at the time of
          entry), with an approximate retail value (ARV) of One Hundred Twenty
          U.S. Dollars (US$120.00).
          <p>&nbsp;</p>
          <p>
            <em>
              All prizes are awarded &ldquo;as is&rdquo; and WITHOUT WARRANTY OF
              ANY KIND, express or implied (including, without limitation, any
              implied warranty of merchantability or fitness for a particular
              purpose).&nbsp; A{' '}
            </em>
            <em>
              prize is NOT transferable and is NOT redeemable or exchangeable
              for cash or other goods or services.&nbsp; Sponsor reserves the
              right to award a substitute prize/prize component of equal or
              greater value.&nbsp; ALL APPLICABLE FEDERAL, STATE AND LOCAL TAXES
              ASSOCIATED WITH THE RECEIPT OR USE OF ANY PRIZE ARE THE SOLE
              RESPONSIBILITY OF EACH WINNER.
            </em>
          </p>
        </li>
        <p>&nbsp;</p>
        <li>
          ODDS OF WINNING: The odds of winning depend on the number of eligible
          entries received.
        </li>
        <p>&nbsp;</p>
        <li>
          <p>
            WINNER SELECTION AND NOTIFICATION; AWARDING OF PRIZE: Within
            approximately one (1) week after the close of the Entry Period, the
            twenty (20) winners will be randomly selected (from among all
            eligible entries received in this Sweepstakes) in a drawing
            conducted by Sponsor. The winners will be notified via email after
            the winner selection is completed.
          </p>
          <p>
            In order to receive the prize, a winner must notify Sponsor via
            email of the desired size and fit for the jersey and return a
            completed and signed winner affidavit and release form (as provided
            by Sponsor) via email to Sponsor, within seven (7) days from the
            time winner notification was sent.&nbsp;{' '}
            <em>
              If a winner resides in Canada, he/she will also be required to
              correctly answer a timed, mathematical skill-testing question in
              order to receive the prize.
            </em>
          </p>
          <p>
            Sponsor will arrange for shipment of the prize (via a shipping
            method determined by Sponsor) to a winner&rsquo;s designated mailing
            address, within approximately three (3) weeks after the winner has
            fulfilled all the aforementioned requirements for receiving the
            prize.
          </p>
          <p>
            Sponsor and the Designated Service Provider will NOT be liable for a
            winner&rsquo;s failure to receive prize notification due to spam,
            junk email or other security settings or for a winner&rsquo;s
            provision of incorrect or otherwise non-functioning contact
            information. &nbsp;If a winner cannot be contacted, or fails to
            claim the prize in a timely manner, or is found to be ineligible, or
            is otherwise disqualified for any reason, the prize will be deemed
            forfeited and an alternate winner will be selected. &nbsp;Awarding
            of the prize is conditioned upon a winner&rsquo;s compliance with
            these Official Rules and all applicable federal, state, and local
            laws. &nbsp;Any violation of these Official Rules or applicable law
            will result in a winner&rsquo;s immediate disqualification to
            receive the prize.
          </p>
        </li>
        <p>&nbsp;</p>
        <li>
          GRANT OF RIGHTS: By entering this Sweepstakes, you irrevocably
          authorize Sponsor, LaLiga and its participating football clubs, Banco
          Santander, S.A., the Designated Service Provider, and their respective
          affiliates, agents and designees (collectively, &ldquo;
          <em>
            <u>Sponsor and Related Parties</u>
          </em>
          &rdquo;), to publish,{' '}
          <em>in the event you are selected as a winner in this Sweepstakes</em>
          , your name, country and state/province of residence, your favorite
          LaLiga football club, and your prize information for advertising,
          promotional and publicity purposes as Sponsor and Related Parties deem
          appropriate, in any and all media (including social media) now known
          or hereafter developed.
        </li>
        <p>&nbsp;</p>
        <li>
          ADDITIONAL TERMS AN CONDITIONS:
          <p>&nbsp;</p>
          <ul>
            <li>
              By entering this Sweepstakes, you understand, acknowledge and
              agree as follows:
              <ul>
                <li>
                  The animated &ldquo;Greenie&rdquo; character and all
                  associated designs and graphic elements made available on the
                  Website for use in generating &ldquo;Greenie&rdquo; superfans,
                  and the &ldquo;Greenie&rdquo; trademark, are proprietary
                  property of Sponsor and are protected by U.S. and foreign
                  intellectual property laws. Sponsor hereby grants you under
                  these Official Rules a limited and non-transferable license to
                  use the same solely in connection with your participation in
                  this Sweepstakes pursuant to these Official Rules.
                </li>
                <li>
                  The animated &ldquo;Greenie&rdquo; character and all
                  associated designs and graphic elements made available on the
                  Website for use in generating &ldquo;Greenie&rdquo; superfans,
                  and the &ldquo;Greenie&rdquo; trademark, are proprietary
                  property of Sponsor and are protected by U.S. and foreign
                  intellectual property laws. Sponsor hereby grants you under
                  these Official Rules a limited and non-transferable license to
                  use the same solely in connection with your participation in
                  this Sweepstakes pursuant to these Official Rules.
                </li>
                <li>
                  Any &ldquo;Greenie&rdquo; superfan character and associated
                  graphics and artwork that you generate in connection with this
                  Sweepstakes, including all associated trademark rights,
                  copyrights, moral rights, and other intellectual property
                  rights, shall belong solely to Sponsor, and you shall NOT have
                  or acquire any right, title or interest whatsoever (including
                  any intellectual property right) in or to any such character
                  or associated graphics or artwork.
                </li>
                <li>
                  Should you have or acquire (whether by operation of law or for
                  any other reason) any right, title or interest in any
                  &ldquo;Greenie&rdquo; superfan character or any associated
                  graphics or artwork that you generate in connection with this
                  Sweepstakes, you hereby irrevocably and unconditionally assign
                  and transfer all such right, title and interest exclusively to
                  Sponsor in perpetuity and throughout the universe. To the
                  extent any such right, title or interest cannot be assigned or
                  transferred by you, you hereby irrevocably and unconditionally
                  waive any such right, title and interest with respect to
                  Sponsor and Related Parties and their respective successors
                  and assigns.
                </li>
                <li>
                  With respect to any &ldquo;Greenie&rdquo; superfan character
                  and associated graphics and artwork that you generate in
                  connection with this Sweepstakes, Sponsor hereby grants you
                  under these Official Rules a limited and non-transferable
                  license to (A) download and retain a copy of such character
                  and associated graphics and artwork{' '}
                  <em>for your own personal and non-commercial use only</em> and
                  (B) upload, post and display in social media a copy of such
                  character and associated graphics and artwork solely in
                  connection with your participation in this Sweepstakes
                  pursuant to these Official Rules, and{' '}
                  <em>
                    not for sale, transfer, rental, licensing, commercial
                    distribution or exploitation, or any other purpose (unless
                    expressly otherwise authorized in writing by Sponsor)
                  </em>
                  .
                </li>
                <li>
                  Sponsor reserves the right to include and display its
                  copyright and/or other proprietary rights notices as part of
                  any &ldquo;Greenie&rdquo; superfan character and associated
                  graphics and artwork that you generate in connection with this
                  Sweepstakes. You shall keep any such notice intact and shall
                  NOT alter, modify, remove, deface, blur, cover, or otherwise
                  temper with any such notice under any circumstance.
                </li>
              </ul>
            </li>
            <p>&nbsp;</p>
            <li>
              By entering this Sweepstakes: (i) you warrant that all personal
              information you provide to Sponsor (whether online or mail-in) is
              your own information and is truthful and accurate; (ii) you
              consent to Sponsor&rsquo;s collection and use of your information
              in accordance with Sponsor&rsquo;s privacy policy posted on the
              Website; and (iii) you also agree to receive marketing and
              promotional emails from Sponsor at your email address (as provided
              on the entry form).
            </li>
            <p>&nbsp;</p>
            <li>
              By entering this Sweepstakes, you agree to indemnify, defend, and
              hold harmless Sponsor and Related Parties from and against any
              third-party claims arising out of or related to: (i) your
              participation in this Sweepstakes; (ii) your violation of these
              Official Rules or any applicable law; and/or (iii) your use or
              misuse of any prize awarded in this Sweepstakes.
            </li>
            <p>&nbsp;</p>
            <li>
              By entering this Sweepstakes, you forever release Sponsor and
              Related Parties from any and all liabilities whatsoever arising
              out of or related to this Sweepstakes or any prize awarded in this
              Sweepstakes, including, without limitation, personal injury or
              death, damage to or loss of property, violation of right of
              publicity or privacy, defamation, libel, and false light, whether
              intentional or unintentional, and whether under a theory of
              contract, tort (including negligence), warranty or otherwise.
            </li>
            <p>&nbsp;</p>
            <li>
              Sponsor reserves the right, in its sole discretion, to cancel or
              suspend part or all of this Sweepstakes should virus, bugs,
              non-authorized human intervention or other causes beyond
              Sponsor&rsquo;s reasonable control corrupt or impair the
              administration, security, fairness or proper operation of this
              Sweepstakes. Sponsor and the Designated Service Provider are NOT
              responsible for: any late, lost, stolen, forged, mutilated,
              corrupted, incomplete, illegible or misdirected entry; any error,
              omission, misinformation, or misidentification in any entry; any
              failure or interruption of any third-party website (including
              Facebook, Twitter, and Instagram); any failure or interruption of
              Sponsor&rsquo;s or the Designated Service Provider&rsquo;s email
              server; any machine or equipment failure or malfunction,
              electrical service interruption, or any Act of God; and/or any
              damage to your or any other person&rsquo;s computer hardware,
              software or data that results from participation in this
              Sweepstakes or accessing, downloading or using any tools, files,
              data, software, or other materials in connection with this
              Sweepstakes.
            </li>
            <p>&nbsp;</p>
            <li>
              These Official Rules are governed by and shall be construed in
              accordance with the laws of the State of California (without
              regard to the conflict of laws provisions thereof). By
              participating in this Sweepstakes, you agree that any and all
              disputes, controversies, claims and causes of action arising out
              of or related to this Sweepstakes, shall be resolved individually,
              without resort to any form of class action, exclusively before a
              federal or state court located in the City of San Francisco,
              California, U.S.A. &nbsp;Further, in any action or proceeding
              arising out of or related to this Sweepstakes, under no
              circumstances will you be permitted to obtain awards for, and you
              hereby waive all rights to, punitive, incidental, or consequential
              damages, including reasonable attorney&rsquo;s fees, other than
              your actual out-of-pocket expenses associated with entering this
              Sweepstakes. &nbsp;You further waive all rights to have damages
              multiplied or increased.
            </li>
            <p>&nbsp;</p>
            <li>
              If any provision of these Official Rules is held by any court of
              competent jurisdiction to be invalid, illegal, or unenforceable,
              such provision will be deemed modified in such manner as to render
              such provision valid, legal, and enforceable to the fullest extent
              permitted by law in such jurisdiction. The remaining provisions of
              these Official Rules shall not be affected thereby, and shall
              continue in full force and effect.
            </li>
            <p>&nbsp;</p>
            <li>
              <em>
                THIS SWEEPSTAKES IS IN NO WAY SPONSORED, ENDORSED OR
                ADMINISTERED BY, OR ASSOCIATED WITH{' '}
              </em>
              Facebook, Twitter, OR Instagram
              <em>
                . BY PARTICIPATING IN THIS SWEEPSTAKES, YOU UNDERSTAND THAT YOU
                ARE PROVIDING INFORMATION TO SPONSOR AND NOT TO{' '}
              </em>
              <em>Facebook, Twitter, OR Instagram</em>
              <em>.</em>
            </li>
          </ul>
        </li>
        <p>&nbsp;</p>
        <li>
          CONTACT SPONSOR: For a copy of the winners list and/or these Official
          Rules, please mail a written request with a self-addressed,
          business-size return envelope to Greenpark Sports, Inc., 1290 Howard
          Avenue, Suite 320, Burlingame, California 94010, ATTN.: LaLiga
          SuperFan Sweepstakes, within thirty (30) days after the close of the
          Entry Period.
        </li>
      </ol>
    </div>
  </InfoPage>
);

export default SuperFanOfficialRules;
