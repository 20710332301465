import React from 'react';
import stickerGreenies from '../assets/images/community-headline-stickers.png';

const Headline = () => {
  return (
    <div className="headline-section">
      <div className="container section-content">
        <div className="row align-items-center">
          <div className="col-xs-12 col-lg-5">
            <div className="title-text-section">
              <h2 className="section-title green">
                JOIN US IN BUILDING THE FUTURE OF FANDOM
              </h2>
            </div>
          </div>
          <div className="col-xs-12 col-lg-7">
            <div className="section-image-wrapper">
              <img
                src={stickerGreenies}
                alt="sticker greenie group"
                className="img-fluid section-image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Headline;
