/* eslint-disable react/jsx-no-target-blank */
import React, {FunctionComponent} from 'react';

interface BlogItemProps {
  featuredImage: string;
  title: string;
  author: string;
  avatar: string;
  date: string;
  readTime: string;
  blogUrl: string;
}

const BlogItem: FunctionComponent<BlogItemProps> = (props: BlogItemProps) => {
  return (
    <div className="col-lg-4 blog-item">
      <a href={props.blogUrl} target="_blank">
        <div className="featured-image">
          <img
            className="img-fluid"
            src={props.featuredImage}
            alt="featured blog"
          />
        </div>
        <div className="content-wrapper">
          <div className="blog-description-top">
            <p className="blog-item-title">{props.title}</p>
          </div>
          <div className="blog-description-bottom">
            <div className="avatar">
              <img
                src={props.avatar}
                alt={props.author}
                className="author-avatar img-fluid"
              />
            </div>
            <div className="blog-item-meta">
              <p className="author">{props.author}</p>
              {/* 
              <p className="info">
                <span className="date">{props.date}</span> &bull;{' '}
                <span className="read-time">{props.readTime}</span>
              </p>
              */}
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

export default BlogItem;
