import React from 'react';
import Headroom from 'react-headroom';
import {Helmet} from 'react-helmet';
import Headline from './CommunityHeadline';
import Links from './CommunityLinks';
import Noise from './CommunityNoise';
import Signs from './CommunitySigns';
import Footer from './Footer';
import Header from './Header';

const Community = () => {
  return (
    <div className="main">
      <Helmet>
        <title>GreenPark Sports</title>
      </Helmet>
      <Headroom>
        <Header />
      </Headroom>
      <Headline />
      <Links />
      <Signs />
      <Noise />
      <Footer />
    </div>
  );
};

export default Community;
