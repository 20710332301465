import React from 'react';
import Headroom from 'react-headroom';
import {Helmet} from 'react-helmet';
import AllThingsGreen from './AllThingsGreen';
// import BeTheFirst from './BeTheFirst';
import BgSplitter from './BgSplitter';
// import DataPartner from './DataPartner';
import Fans from './Fans';
import Footer from './Footer';
import GearItems from './GearItems';
import Header from './Header';
import Hero from './Hero';
import HowToGreenpark from './HowToGreenPark';
// import Partner from './Partner';
// import Talent from './Talent';
// import Victor from './Victor';

const HomePage = () => {
  return (
    <div className="main">
      <Helmet>
        <title>GreenPark Sports</title>
      </Helmet>
      <Headroom>
        <Header />
      </Headroom>
      <Hero />
      <Fans />
      <BgSplitter />
      <HowToGreenpark />
      {/* <Victor /> */}
      {/* <BeTheFirst /> */}
      {/* <Partner /> */}
      {/* <Talent /> */}
      <GearItems />
      {/* <DownloadCTA /> */}
      <AllThingsGreen />
      {/* <DataPartner /> */}
      <Footer />
    </div>
  );
};

export default HomePage;
