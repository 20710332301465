import React, {FunctionComponent} from 'react';

interface ButtonProps {
  ctaUrl?: string;
  target?: string;
  ctaClass?: string;
}

export const ButtonLink: FunctionComponent<ButtonProps> = ({
  children,
  ctaUrl,
  target,
  ctaClass,
}) => {
  const classList = `${ctaClass} button button-link`;
  return (
    <a href={ctaUrl} target={target} className={classList}>
      {children}
    </a>
  );
};
