import React from 'react';
import {Helmet} from 'react-helmet';
import AppleAppStore from '../assets/images/app-store-cta.png';
import LcsLogo from '../assets/images/lcs-logo.png';
import GooglePlayStore from '../assets/images/play-store-cta.png';
import SignupGauge from '../assets/images/signup-gauge.png';
import Footer from './Footer';
import Logo from './Logo';

const Signup = () => {
  return (
    <div className="signup-page">
      <Helmet>
        <title>GreenPark Sports</title>
      </Helmet>
      <div className="signup-page">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="signup-header">
                <a href="/">
                  <Logo small />
                </a>
                <span className="signup-lcs-logo">
                  <img className="img-fluid" src={LcsLogo} alt="LCS Logo" />
                </span>
              </div>
              <div className="signup-hero">
                <h1 className="signup-hero-text">
                  Early Access is Open.<span>Jump In.</span> It Won't Last Long.
                </h1>
                <p className="signup-hero-subtext">
                  Download GreenPark today and bring your friends. The more
                  players who join, the more exclusive LCS gear you unlock.
                </p>
                <div className="signup-hero-cta">
                  <a
                    href="https://app.adjust.com/3fpdbeb?campaign=Website&adgroup=SignUpPage"
                    // eslint-disable-next-line react/jsx-no-target-blank
                    target="_blank"
                  >
                    <img
                      src={GooglePlayStore}
                      alt="Google Play Store Download CTA"
                      className="download-cta-img img-fluid"
                    />
                  </a>
                  <a
                    href="https://app.adjust.com/5qr9jx7?campaign=Website&adgroup=SignUpPage"
                    // eslint-disable-next-line react/jsx-no-target-blank
                    target="_blank"
                  >
                    <img
                      src={AppleAppStore}
                      alt="Apple App Store Download CTA"
                      className="download-cta-img img-fluid"
                    />
                  </a>
                </div>
              </div>
              <div className="signup-content">
                <img src={SignupGauge} alt="LCS Signup Guage and Prizes" />
              </div>
              <div className="signup-cta">
                <p className="signup-callout">
                  <span role="img" aria-label="don't miss out">
                    😵
                  </span>{' '}
                  Promotion Ends 3/31.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Signup;
