import React from 'react';
import twitterNavbarIcon from '../assets/images/twitterNavbarIcon.svg';
import discordNavbarIcon from '../assets/images/discordNavbarIcon.svg';
import Logo from './Logo';

const Header = () => {
  const [open, setOpen] = React.useState(false);
  return (
    <header className="header">
      <div className="top-bar">
        <Burger open={open} setOpen={setOpen} />
        <a href="/" className="desktop-logo">
          <Logo small />
        </a>
        <MainNav open={open} setOpen={setOpen} />
        {/* <div className="callout">
          <a
            className="callout-link"
            href="https://app.adjust.com/5qr9jx7_3fpdbeb?campaign=Website2&adgroup=TopRight"
          >
            <img src={foamfinger} alt="foam finger" className="foam-finger" />
            <p className="callout-text">DOWNLOAD THE APP</p>
          </a>
        </div> */}
      </div>
    </header>
  );
};

interface ManNavProps {
  open: boolean;
  setOpen: any;
}

const MainNav = ({open}: ManNavProps) => {
  return (
    <nav className={`main-nav ${open ? 'open' : ''}`}>
      <ul className="nav-list">
        {/* <li className="nav-item">
          <a
            href="https://blog.greenparksports.com/invite"
            className="nav-link"
          >
            Invite
          </a>
        </li> 
        <li className="nav-item">
          <a href="https://blog.greenparksports.com" className="nav-link">
            Blog
          </a>
        </li>
        <li className="nav-item">
          <a href="https://blog.GreenParksports.com/help" className="nav-link">
            Help
          </a>
        </li>
        */}
        <li className="nav-item">
          <a href="https://blog.GreenParksports.com/" className="nav-link">
            NEWS
          </a>
        </li>
        <li className="nav-item">
          <a href="https://blog.GreenParksports.com/help" className="nav-link">
            SUPPORT
          </a>
        </li>
        <li className="nav-item">
          <a href="https://GreenParksports.com/community" className="nav-link">
            COMMUNITY
          </a>
        </li>
        <li className="nav-item">
          <a href="https://nft.greenparksports.com" className="nav-link">
            NFT
          </a>
        </li>
      </ul>
      <div className="social-icons">
        <li className="nav-item">
          <a href="https://twitter.com/greenparksports" className="social-link">
            <img src={twitterNavbarIcon} />
          </a>
        </li>
        <li className="nav-item">
          <a href="https://discord.com/invite/p5p2gPE" className="social-link">
            <img src={discordNavbarIcon} />
          </a>
        </li>
      </div>
      <div className="mobile-hamburger-logo">
        <a href="/" className="mobile-nav-logo">
          <Logo small />
        </a>
      </div>
    </nav>
  );
};

interface BurgerProps {
  open: boolean;
  setOpen: any;
}

const Burger = ({open, setOpen}: BurgerProps) => {
  return (
    <button
      className={`hamburger hamburger--elastic ${open ? 'is-active' : ''}`}
      type="button"
      // tslint:disable-next-line:jsx-no-lambda
      onClick={() => setOpen(!open)}
    >
      <span className="hamburger-box">
        <span className="hamburger-inner" />
      </span>
    </button>
  );
};

export default Header;
