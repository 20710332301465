import React from 'react';
import InfoPage from './InfoPage';

const Terms = () => (
  <InfoPage>
    <div className="subpage-main-content">
      <h1>NBA Marketing Consent Details</h1>
      <p>
        If you consent, GreenPark will share with the NBA your email address,
        date of birth, favorite NBA team, and information about your activities
        in the NBA-branded areas within the GreenPark app.
      </p>
      <p>&nbsp;</p>
      <h2>
        <strong>NBA Disclaimer</strong>
      </h2>
      <p>
        By agreeing to the above terms, you are consenting that your personal
        information will be collected, stored and processed in the United States
        and/or the European Union on behalf of NBA Properties, Inc. Details
        about how to withdraw your consent are available in the{' '}
        <a
          href="https://www.nba.com/news/privacy_policy.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          NBA Privacy Policy
        </a>
        .
      </p>{' '}
    </div>
  </InfoPage>
);

export default Terms;
