import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import './styles/index.scss';
import './styles/normalize.css';
import App from './views/App';
import {GPSCookieConsent} from './views/CookieConsent';

ReactDOM.render(
  <BrowserRouter>
    <App />
    <GPSCookieConsent />
  </BrowserRouter>,
  document.getElementById('root'),
);
