import React from 'react';
import InfoPage from './InfoPage';

const Terms = () => (
  <InfoPage title="TERMS OF USE">
    <div className="subpage-main-content">
      <h1>Terms of Use</h1>
      <h2>
        <strong>Last Updated: November 12, 2021</strong>
      </h2>
      <p>
        These Terms of Use govern the use of the GreenPark Services (defined
        below) which are provided by GreenPark Sports, Inc., located at 1290
        Howard Avenue, Suite 320, Burlingame, California 94010 (hereinafter,{' '}
        <em>"GreenPark</em>&rdquo; or <em>&ldquo;we&rdquo;</em> or{' '}
        <em>&ldquo;us&rdquo;</em>).
      </p>
      <p>
        By using any of the GreenPark Services, you accept and agree to be bound
        by these Terms of Use as well as our{' '}
        <a href="https://greenparksports.com/privacy">Privacy Policy</a>. If you
        do not agree with any provision of these Terms of Use or our{' '}
        <a href="https://greenparksports.com/privacy">Privacy Policy</a>, please
        do not use the GreenPark Services.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          <em>
            <u>IMPORTANT NOTICE TO ALL USERS</u>
          </em>
        </strong>
        <strong>: </strong>
        <strong>
          THESE TERMS OF USE CONTAIN PROVISIONS THAT LIMIT OUR LIABILITY TO YOU
          AND REQUIRE YOU TO RESOLVE ANY DISPUTE WITH US THROUGH BINDING
          ARBITRATION ON AN INDIVIDUAL BASIS AND NOT AS PART OF ANY CLASS OR
          REPRESENTATIVE ACTION. PLEASE SEE THE &ldquo;
        </strong>
        <strong>
          <u>
            <a href="#no-warranties">No Warranties; Limitation of Liability</a>
          </u>
        </strong>
        <strong>&rdquo; AND &ldquo;</strong>
        <strong>
          <u>
            <a href="#arbitration-agreement">
              Arbitration Agreement and Class Action Waiver
            </a>
          </u>
        </strong>
        <strong>&rdquo; SECTIONS OF THESE TERMS OF USE FOR DETAILS.</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>CERTAIN DEFINITIONS</strong>
      </p>
      <p>As used in these Terms of Use:</p>
      <ul>
        <li>
          <em>"Content"</em>&nbsp; means, collectively, all content, information
          and material made available by GreenPark and/or our Suppliers on or
          through the GreenPark Services, including, without limitation, text,
          photos, images, graphics, designs, artwork, emojis, avatars,
          characters (whether or not animated), animations, music, audios,
          videos, games, and software applications, whether or not downloadable,
          as well as all layout design and look and feel elements of the
          GreenPark Services. For the avoidance of doubt, all Virtual Goods
          (defined below) are and shall be deemed to be part of the Content.{' '}
          <em>However, Content does not include User Material.</em>
        </li>
        <li>
          <em>&ldquo;GreenPark Services&rdquo;</em>&nbsp; means, collectively,
          any and all GreenPark-branded online and mobile application products
          and services as may be operated or made available by GreenPark,
          whether existing now or in the future, including, without limitation,
          the GreenParkSports.com website, the GreenPark NFT Marketplace
          (defined in our{' '}
          <a href="https://greenparksports.com/nftterms">NFT Terms</a>), the
          GreenPark mobile app, the GreenPark Superfan Studio, and
          GreenPark-operated social media channels.
        </li>
        <li>
          <em>&ldquo;NFT&rdquo;</em>&nbsp; means a digital collectible item
          released by (or on behalf of) GreenPark, the ownership of which item
          is represented by a blockchain-based non-fungible token. For clarity,
          all NFTs constitute Virtual Goods (defined below).
        </li>
        <li>
          <em>"Suppliers"</em>&nbsp; means all of the following: (i) third
          parties that have licensed their intellectual property or other
          proprietary rights to GreenPark for use in connection with the
          GreenPark Services, including, for example, third-party sports leagues
          who have licensed their trademarks, logos, content, and/or other
          intellectual property to our company; (ii) our business partners; and
          (iii) third parties that provide software, data, and/or other
          information technology products, services, and/or support to GreenPark
          in connection with the operation of the GreenPark Services.
        </li>
        <li>
          <em>"User"</em> or <em>"you"</em>&nbsp; means anyone who uses any of
          the GreenPark Services. In the event an individual uses any of the
          GreenPark Services on behalf of or at the direction of an entity, such
          individual and such entity shall both be deemed Users for the purposes
          of these Terms of Use and our{' '}
          <u>
            <a href="https://greenparksports.com/nftterms">NFT Terms</a>
          </u>
          .
        </li>
        <li>
          <em>"User Material"</em>&nbsp; means content, information or material
          that a User originates and makes available to others on or through the
          GreenPark Services, but excluding: (i) content, information or
          material that belongs to GreenPark or our Suppliers; and (ii) your
          personal information that you provide to us when creating an account
          on the GreenPark Services (such personal information is governed by
          our <a href="https://greenparksports.com/privacy">Privacy Policy</a>).
        </li>
        <li>
          <em>&ldquo;Virtual Goods&rdquo;</em>&nbsp; means digital virtual goods
          made available by GreenPark and/or our Suppliers to Users on or
          through the GreenPark Services (i) for purchase or (ii) as rewards,
          prizes, or promotional items. The following are examples of Virtual
          Goods: in-game virtual currency, reward points, tokens, avatars,
          gamertags, skins, emotes, gears, accessories, stickers, cards, and
          other collectibles, including NFTs.
        </li>
      </ul>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>CHANGES</strong>
      </p>
      <p>
        From time to time we may modify these Terms of Use. If we make material
        modifications to these Terms of Use, we will make efforts to notify you
        in advance, such as by email or by posting a notice on the GreenPark
        Services (e.g., in-app notification). Unless we state otherwise, if you
        continue to use any of the GreenPark Services after such notification,
        these Terms of Use as modified will apply to you.
        <em>
          If you do not agree with any modification to these Terms of Use, your
          sole and exclusive remedy will be to discontinue use of the GreenPark
          Services.
        </em>
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>ADDITIONAL TERMS</strong>
      </p>
      <p>
        To the extent GreenPark and/or our Suppliers adopt and publish
        additional terms, rules or policies for any of the GreenPark Services or
        any Content (including Virtual Goods), you accept and agree to be bound
        by such additional terms/rules/policies, in addition to these Terms of
        Use, in connection with your use of the GreenPark Services or Content to
        which such additional terms/rules/policies pertain.
      </p>
      <ul>
        <li>
          <u>NFTs</u>: From time to time, GreenPark makes NFTs available for
          purchase by Users and/or as rewards or prizes to Users, and may also
          allow Users to convert (“minting”) certain Virtual Goods they already
          have into NFTs. All NFTs are subject to our{' '}
          <u>
            <a href="https://greenparksports.com/nftterms">NFT Terms</a>
          </u>{' '}
          in addition to these Terms of Use. Our{' '}
          <u>
            <a href="https://greenparksports.com/nftterms">NFT Terms</a>
          </u>{' '}
          constitute an integral part of these Terms of Use and are incorporated
          herein by reference. In the event of a conflict between any of the
          provisions contained in our{' '}
          <u>
            <a href="https://greenparksports.com/nftterms">NFT Terms</a>
          </u>{' '}
          and any of the provisions contained in these Terms of Use, the
          provisions contained in our{' '}
          <u>
            <a href="https://greenparksports.com/nftterms">NFT Terms</a>
          </u>{' '}
          shall control solely to the extent of the conflict and solely in
          connection with NFTs.
        </li>
        <li>
          <u>Other Virtual Goods</u>: From time to time, GreenPark and/or our
          Suppliers may adopt additional terms, rules or policies for specific
          Virtual Goods. In the event of a conflict between these Terms of Use
          and any additional terms/rules/policies applicable to specific Virtual
          Goods, such additional terms/rules/policies will control solely to the
          extent of the conflict and solely in connection with the applicable
          Virtual Goods.
        </li>
        <li>
          <u>Promotions</u>: From time to time, GreenPark and/or our Suppliers
          may conduct sweepstakes, contests, giveaways, etc. (
          <em>“Promotions”</em>) on or through the GreenPark Services, and
          participation in such Promotions will be subject to their official
          rules (as prescribed by GreenPark and/or our Suppliers) in addition to
          these Terms of Use.
        </li>
      </ul>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>CONSENT TO ELECTRONIC COMMUNICATIONS</strong>
      </p>
      <p>
        By signing up for any of the GreenPark Services, you consent to
        receiving electronic communications from us that are related to your use
        of the GreenPark Services. These electronic communications may include
        emails, in-app notifications, and notifications posted to your account
        on the GreenPark Services. You agree that all agreements, notices,
        disclosures, and other communications that we provide to you
        electronically via email satisfy any legal requirement that the same be
        in writing.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>ACCOUNT REGISTRATION</strong>
      </p>
      <p>
        By registering an account on the GreenPark Services, you warrant that
        all account information you submit is your own information and is
        truthful and accurate. You understand and agree that we may immediately
        suspend or terminate your account if we have any reason to believe that
        you have misrepresented or provided false account information to us. It
        is your responsibility to keep your account information accurate and
        current. As the account holder, you are responsible for safeguarding
        your account login information (username and password), and you should
        notify us immediately in the event your account login is lost, stolen,
        or used by another without your permission. You are responsible for all
        activities on your account, including those of any User you allow to
        access your account, and any misconduct by you or any such User may
        result in termination of your account.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>THIRD-PARTY APP STORE PROVIDERS</strong>
      </p>
      <p>
        Third-party app store providers, including Apple, Inc., Google LLC,
        Samsung Electronics Co., Ltd., and Amazon.com, Inc., together with their
        affiliates (each, an <em>&ldquo;App Store Provider&rdquo;</em>), are
        third-party beneficiaries to these Terms of Use .&nbsp; Upon your
        acceptance of these Terms of Use, the applicable App Store Provider will
        have the right (and will be deemed to have accepted the right) to
        enforce these Terms of Use against you as a third-party beneficiary
        hereof.
      </p>
      <p>&nbsp;</p>
      <p>
        When accessing the GreenPark Services on a mobile device (Apple iOS or
        Android), you acknowledge and agree as follows:
      </p>
      <ul>
        <li>
          You are also bound by and must comply with the terms and policies of
          the applicable App Store Provider;
        </li>
        <li>
          These Terms of Use are solely between you and GreenPark, and the
          applicable App Store Provider is not a party to these Terms of Use;
        </li>
        <li>
          GreenPark, not the applicable App Store Provider, is solely
          responsible for the GreenPark Services and Content (including Virtual
          Goods);
        </li>
        <li>
          You are accessing the GreenPark Services on a mobile device owned or
          controlled by you and as permitted by the usage rules set forth in the
          terms and policies of the applicable App Store Provider;
        </li>
        <li>
          The applicable App Store Provider has no obligation whatsoever to
          provide maintenance or support with respect to the GreenPark Services;
        </li>
        <li>
          To the maximum extent permitted by applicable law, the applicable App
          Store Provider has no warranty obligation whatsoever with respect to
          the GreenPark Services;
        </li>
        <li>
          GreenPark, not the applicable App Store Provider, is responsible for
          addressing any claims by Users or third parties relating to the
          GreenPark Services, including (but not limited to) product liability
          claims, claims that the GreenPark Services fail to conform to any
          applicable legal or regulatory requirement, and claims arising under
          consumer protection, privacy, or similar legislation; and
        </li>
        <li>
          In the event of any third party claim that the GreenPark Services (or
          use thereof) infringe a third party&rsquo;s intellectual property
          rights, GreenPark, not the applicable App Store Provider, will be
          solely responsible for the investigation, defense, settlement and
          discharge of such third party claim.
        </li>
      </ul>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>MOBILE SERVICE CHARGES</strong>
      </p>
      <p>
        If you use any of the GreenPark Services on a mobile device, or if you
        send or receive electronic communications to or from us on a mobile
        device, your wireless carrier may impose data and/or other charges, and
        you understand and agree that you will be solely responsible for any and
        all such charges from your wireless carrier.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>COMPLIANCE WITH EXPORT CONTROL LAWS</strong>
      </p>
      <p>
        You agree not to export, re-export, or transfer, directly or indirectly,
        any of the GreenPark Services, or any U.S. technical data acquired from
        any of the GreenPark Services, or any products utilizing such data, in
        violation of the U.S. export laws or regulations.&nbsp; By accessing any
        of the GreenPark Services, you represent and warrant that: (i) you are
        not located in a country that is subject to a U.S. Government embargo,
        or that has been designated by the U.S. Government as a &ldquo;terrorist
        supporting&rdquo; country; and (ii) you are not listed on any U.S.
        Government list of prohibited or restricted parties.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>RESERVATION OF RIGHTS</strong>
      </p>
      <p>
        We reserve the right to modify or discontinue any or all of the
        GreenPark Services or any Content (including Virtual Goods) at any time
        in our sole discretion, with or without notice. We will not be liable to
        you or to any other User, if for any reason any or all of the GreenPark
        Services or any Content (including Virtual Goods) becomes unavailable at
        any time or in any location. If you register an account on the GreenPark
        Services, we reserve the right to suspend or terminate your account, if
        we determine (in our sole judgment) that you are in violation of these
        Terms of Use or any applicable law or that your use of the GreenPark
        Services may expose our company or any of our affiliates or Suppliers to
        liability of any kind, or may adversely affect the brand or reputation
        of our company.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>INTELLECTUAL PROPERTY</strong>
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            <strong>
              <u>GreenPark Intellectual Property</u>
            </strong>
            <strong>:</strong> Other than intellectual property licensed from
            our Suppliers, all of the GreenPark Services and Content, including
            all copyrights, trademark rights, trade dress rights, patent rights,
            and other intellectual property and proprietary rights therein and
            thereto, are the property of GreenPark and/or our affiliates, and
            are protected by intellectual property and other laws in the United
            States and other countries.
          </p>
          <p>&nbsp;</p>
          <p>
            Subject to your compliance with these Terms of Use and applicable
            laws, we grant you a non-transferable, revocable, conditional and
            limited license to access and use the GreenPark Services and Content
            (including Virtual Goods){' '}
            <em>for your own lawful personal and noncommercial use only</em>.
            This license is personal to you and is{' '}
            <strong>
              <em>not</em>
            </strong>{' '}
            transferable or sublicensable to others, and may be revoked and
            terminated by us at any time and for any reason (including, without
            limitation, if you violate these Terms of Use or any applicable
            law). Any unauthorized use, copying, reproduction or distribution of
            the GreenPark Services or Content (including Virtual Goods) is
            strictly prohibited and may result in civil and/or criminal
            liabilities. We reserve all rights not expressly granted herein.
          </p>
          <p>&nbsp;</p>
          <p>
            Without limitation to the generality of the foregoing, if you
            download, unlock, receive, purchase, or otherwise acquire any
            Content (including any Virtual Goods) from the GreenPark Services,
            you acknowledge and agree that you are only authorized to retain and
            use such Content (including Virtual Goods, in accordance with any
            additional terms applicable thereto){' '}
            <em>for your own lawful personal and noncommercial use only</em> and
            that, unless expressly otherwise permitted by GreenPark, you are{' '}
            <strong>
              <em>not</em>
            </strong>{' '}
            permitted to sell, offer to sell, distribute, transfer, license,
            transmit, or otherwise disseminate such Content (including Virtual
            Goods) or any duplicate or derivative work thereof, to others.
          </p>
          <p>&nbsp;</p>
          <p>
            The terms GREENPARK, GREEN PARK, GRIME, MAY THE BEST FANS WIN,
            GREENIE, GREENIE RUSH, FANERGY, and THE VIRTUES AWAKEN (including
            their associated logos and stylized variations), as well as all the
            Foamie designs, are trademarks of GreenPark. The Greenie characters,
            the Foamie characters, and all other original characters, designs
            and artwork visible within the GreenPark Services, including any and
            all of their existing and future renditions, and including also any
            and all User-customized or User-personalized versions of such
            characters, designs and artwork, are copyrights of GreenPark.
            GreenPark&rsquo;s trademarks and copyrights are protected by laws in
            the U.S. and other countries. Except as specifically permitted by
            applicable laws, any unauthorized use of our trademarks or
            copyrights is strictly prohibited and may give rise to civil and/or
            criminal liabilities.
          </p>
          <p>&nbsp;</p>
          <p>
            <em>
              <u>SPECIAL NOTE</u>:
              <em>
                {''} On the GreenPark Services, sports fans are represented by
                an animated character called &ldquo;Greenie.&rdquo; Certain
                features of the GreenPark Services (such as the GreenPark
                Superfan Studio) allow Users to generate customized or
                personalized versions of Greenie. By using such features, you
                acknowledge and agree that: (i) all your customized or
                personalized versions of Greenie, including all associated
                trademark rights, copyrights, and other intellectual property
                rights, constitute the sole and exclusive property of GreenPark
                and shall be treated as Content and <strong>NOT</strong> as User
                Material; (ii) you have and acquire no ownership right or
                interest whatsoever in your customized or personalized versions
                of Greenie; and (iii) to the extent GreenPark authorizes you to
                publish your customized or personalized versions of Greenie in
                social media, you may do so, provided that you abide by all the
                terms and rules prescribed by GreenPark that govern such
                publishing.
              </em>
            </em>
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>
            <u>Our Suppliers&rsquo; Intellectual Property</u>
          </strong>
          <strong>:</strong> Intellectual property licensed from or otherwise
          made available by our Suppliers remains the property of our Suppliers.
          Examples of our Suppliers&rsquo; intellectual property include (but
          are not limited to): marks, logos and other brand identifications of
          our sports league partners and team partners; game/match/event data,
          stats, photos, and video/audio footage; player names, likenesses,
          photos, voices, bios, jerseys, and other player identifications; and
          marks, logos, and other brand identifications of third-party sponsors
          and advertisers.
        </li>
      </ul>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>PROHIBITED USES</strong>
      </p>
      <p>
        You shall{' '}
        <strong>
          <em>not</em>
        </strong>
        :
      </p>
      <ul>
        <li>
          use the GreenPark Services or Content (including Virtual Goods){' '}
          <em>
            other than for your own lawful personal and noncommercial use only
          </em>
          ;
        </li>
        <li>
          use the GreenPark Services or Content (including Virtual Goods) for
          fraudulent, impersonation, harassment, bullying, or other malicious or
          unlawful purposes;
        </li>
        <li>
          use the GreenPark Services to upload, post, display, transmit or
          distribute User Material that (x) is unlawful, threatening, hateful,
          discriminatory, abusive, pornographic, obscene, defamatory,
          fraudulent, or otherwise objectionable or offensive, or (y) that
          contains virus, spyware, malware, or other harmful code or material,
          or (z) that you do not have a legal right or valid authorization to
          disseminate;
        </li>
        <li>
          use the GreenPark Services or Content (including Virtual Goods) to
          harm or exploit a minor;
        </li>
        <li>
          disrupt or interfere in any way with the operation of the GreenPark
          Services or any server, network or system associated therewith, or
          another&rsquo;s use of the GreenPark Services;
        </li>
        <li>
          modify or otherwise make derivative works from, or decompile,
          disassemble or otherwise reverse engineer, any software or other
          technology component of the GreenPark Services;
        </li>
        <li>
          disable, remove, breach, or circumvent any content protection or
          access control mechanisms (including geo-blocking mechanisms)
          associated with the GreenPark Services or any Content (including
          Virtual Goods);
        </li>
        <li>
          access another&rsquo;s account or a password-protected area on the
          GreenPark Services without proper authorization;
        </li>
        <li>
          use any robot, spider, or other automatic device, process, or means to
          access the GreenPark Services or Content (including Virtual Goods) for
          any purpose, including for monitoring or mining of data from the
          GreenPark Services;
        </li>
        <li>
          use the GreenPark Services or Content (including Virtual Goods) in any
          way that infringes upon, violates, or misappropriates the intellectual
          property or other proprietary rights of GreenPark or any of our
          Suppliers; or
        </li>
        <li>
          use the GreenPark Services or Content (including Virtual Goods) for
          any purpose that violates these Terms of Use or any applicable law.
        </li>
      </ul>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        To report violations of the above restrictions, please email{' '}
        <a href="mailto:support@greenparksports.com">
          support@greenparksports.com
        </a>
        .
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>VIRTUAL GOODS</strong>
      </p>
      <p>
        Except as expressly otherwise stated in our{' '}
        <u>
          <a href="https://greenparksports.com/nftterms">NFT Terms</a>
        </u>{' '}
        as pertaining to NFTs, all Virtual Goods (including all associated
        intellectual property and proprietary rights) will remain the property
        of GreenPark or our Suppliers (as applicable), and you have no ownership
        or other property right or interest in any Virtual Goods, regardless of
        how you obtain access to it. 
      </p>
      <p>&nbsp;</p>
      <p>
        Unless expressly otherwise indicated by GreenPark or our Suppliers,
        GreenPark and our Suppliers do{' '}
        <em>
          <strong>not</strong>
        </em>{' '}
        represent or warrant that Virtual Goods have any monetary value or that
        they can be redeemed or exchanged for any type of “real world” money.
      </p>
      <p>
        <strong>
          <em>
            THERE IS NO REFUND OR RETURN FOR THE PURCHASE OF ANY VIRTUAL GOODS
          </em>
        </strong>
        , unless expressly authorized by GreenPark or our Suppliers or by an
        applicable App Store Provider.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>USER FEEDBACK</strong>
      </p>
      <p>
        If you submit ideas, suggestions, comments, or other feedback concerning
        any of the GreenPark Services or Content (including Virtual Goods)
        (whether solicited or unsolicited) (<em>&ldquo;Feedback&rdquo;</em>) to
        GreenPark, you understand and agree that: (i) GreenPark and our
        affiliates and Suppliers, including our and their successors and
        assigns, will be free to copy and use your Feedback for any and all
        commercial and noncommercial purposes (including, without limitation,
        for marketing, advertising, promotion, and product/service development);
        (ii) your Feedback submission is voluntary and consensual and&nbsp;
        <em>
          is made without any condition or reservation of rights, including,
          without limitation, any condition of compensation, payment, credit,
          attribution, secrecy or confidentiality
        </em>
        ; (iii) your Feedback submission does not give rise to any contractual,
        fiduciary or confidential relationship of any kind (whether express or
        implied) between you and GreenPark; and (iv) your Feedback submission
        may be used and retained indefinitely by GreenPark and our affiliates
        and Suppliers, including our and their successors and assigns.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>USER INTERACTIONS</strong>
      </p>
      <p>
        Please adhere to the following guidelines when interacting with other
        Users (including, without limitation, when communicating with other
        Users via text and/or audio chat functions) on the GreenPark Services:
      </p>
      <ul>
        <li>Please treat others with courtesy and respect.</li>
        <li>
          Please do not use foul language, profanity, obscenity, or make
          threatening, harassing, or discriminatory remarks toward others.
        </li>
        <li>
          Bullying, trolling, spamming, phishing, impersonation, and other
          abusive behaviors are strictly prohibited.
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        To report violations of the above guidelines, please email{' '}
        <a href="mailto:support@greenparksports.com">
          support@greenparksports.com
        </a>
        .
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>USE OF IN-APP CHAT FUNCTIONS</strong>
      </p>
      <p>
        By using text and/or audio chat functions to communicate with other
        Users on the GreenPark Services, you acknowledge and agree that
        GreenPark and/or our Suppliers reserve the rights (i) to monitor and
        moderate User communications and remove any communications that
        GreenPark and/or our Suppliers may deem (in our and/or their sole
        discretion) to be offensive, inappropriate, or otherwise violative of
        these Terms of Use and (ii) to record, log, and retain copies of User
        communications for product improvement, data research, and archival
        purposes.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        Please exercise utmost discretion and caution if you choose to reveal
        personal information that identifies you or another person in text
        and/or audio communications with other Users.&nbsp; Personal information
        revealed may be used by others in a way that is offensive or harmful to
        you or another person, or in a way that violates your or another
        person&rsquo;s rights. &nbsp;If you choose to reveal personal
        information to another User, you do so of your own volition and at your
        own risk, and you acknowledge and agree that GreenPark and our Suppliers
        will{' '}
        <strong>
          <em>not</em>
        </strong>{' '}
        be responsible or liable for other Users&rsquo; use or misuse of
        information revealed by you, and you waive any and all claims against
        GreenPark and/or our Suppliers based upon or by reason of other
        Users&rsquo; use or misuse of information revealed by you.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>USER MATERIAL</strong>
      </p>
      <p>
        Certain features of the GreenPark Services may provide Users with the
        ability to upload, post, display, transmit or distribute User Material.
        By using such features, you acknowledge and agree as follows:
      </p>
      <ul>
        <li>
          You warrant that your User Material does not violate any third
          party&rsquo;s rights.
        </li>
        <li>
          You grant to GreenPark and our Suppliers an
          <em>
            irrevocable, non-exclusive, worldwide, royalty-free, sublicensable
            and transferable
          </em>{' '}
          license to make your User Material available to others on or through
          the GreenPark Services, as directed by you.
        </li>
        <li>
          We and our Suppliers reserve the right to retain copies of your User
          Material for internal backup/archival purposes, or as may be required
          by applicable law.
        </li>
        <li>
          We and our Suppliers are not responsible or liable for others&rsquo;
          retention, use or misuse of any User Material that you have made
          available to others on or through the GreenPark Services.
        </li>
        <li>
          You are solely responsible for backing up your User Material, and we
          and our Suppliers are <em>not</em> responsible for any loss or
          corruption of your User Material.
        </li>
        <li>
          We and our Suppliers reserve the right to remove and block or disable
          access to your User Material, if it is determined by us and/or our
          Suppliers that your User Material violates these Terms of Use or any
          applicable law, may expose GreenPark or any of our Suppliers to
          liability of any kind, or may adversely affect the reputation or
          goodwill of GreenPark or any of our Suppliers.
        </li>
        <li>
          All User Material is subject to our &rsquo;DMCA Policy&rsquo; below.
        </li>
      </ul>
      <p>
        <strong>DMCA POLICY</strong>
      </p>
      <p>
        GreenPark is committed to complying with U.S. copyright law and expects
        all Users to do the same. Pursuant to the Digital Millennium Copyright
        Act (Title 17, U.S. Code) (&rsquo;DMCA&rsquo;), GreenPark has adopted
        and implemented a policy to provide for the prompt removal of allegedly
        infringing User Material and for the termination of the account (if any)
        of any User who is determined by GreenPark to be a repeat infringer.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        Under the DMCA, copyright owners may contact our designated agent listed
        below to report User Material that is believed to infringe their
        copyrights. Upon receipt of a proper notification that satisfies the
        requirements of the DMCA, we will remove or block access to the
        allegedly infringing User Material. If you believe in good faith that a
        notice of alleged copyright infringement has been wrongly filed against
        your User Material, you may submit a counter-notification in accordance
        with this DMCA Policy. GreenPark shall not be made a party to disputes
        over alleged copyright infringement.
      </p>
      <p>
        <strong>
          <u>Our Designated Agent</u>
        </strong>
        <strong>:</strong> The following is our designated agent for receiving
        notifications of claimed copyright infringement concerning User Material
        on the GreenPark Services:
      </p>
      <p>
        DMCA / Legal<br></br>
        GreenPark Sports, Inc.<br></br>
        1290 Howard Avenue, Suite 320<br></br>
        Burlingame, California 94010<br></br>
        Phone: (818) 275-1552<br></br>
        Email:{' '}
        <a href="mailto:DMCA@greenparksports.com">DMCA@greenparksports.com</a>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          <u>Notice of Claimed Copyright Infringement</u>
        </strong>
        <strong>:</strong>  Notification of claimed copyright infringement must
        be in writing and sent to our designated agent listed above, and must
        include the following information:
      </p>
      <ul>
        <li>
          identification of the copyrighted work claimed to have been infringed,
          or if multiple copyrighted works are covered by a single notification,
          a list of such works;
        </li>
        <li>
          identification of the material to enable GreenPark to locate the
          material that is claimed to be infringing or to be the subject of
          infringing activity and that is to be removed or access to which is to
          be disabled;
        </li>
        <li>
          address, telephone number, and if available, an email address at which
          the complaining party may be contacted;
        </li>
        <li>
          a statement that the complaining party has a good faith belief that
          use of the identified material in the manner complained of is not
          authorized by the copyright owner, its agent, or the law; and
        </li>
        <li>
          a statement that the information in the notification is accurate, and
          under penalty of perjury, that the complaining party is authorized to
          act on behalf of the owner of an exclusive right that is allegedly
          infringed.
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        <strong>
          <u>Counter-Notification to Claimed Copyright Infringement</u>
        </strong>
        <strong>:</strong>  Under the DMCA, if your User Material was removed by
        us in response to a third party’s notification of claimed copyright
        infringement, and if you believe in good faith that the removal was due
        to mistake or misidentification of the material sought to be removed,
        you may make a counter-notification with our designated agent listed
        above. If we receive a valid counter-notification, we may reinstate the
        removed or disabled User Material in accordance with the DMCA. A
        counter-notification must be in writing and include the following
        information:
      </p>
      <ul>
        <li>
          identification of the material that has been removed or to which
          access has been disabled and the location at which the material
          appeared before it was removed or access to it was disabled;
        </li>
        <li>
          a statement by you under penalty of perjury that you have a good faith
          belief that the material was removed or disabled as a result of
          mistake or misidentification; and
        </li>
        <li>
          your name, address, telephone number and (if available) email address,
          and a statement that you consent to the jurisdiction of Federal
          District Court for the judicial district in which you are located
          (or in the State of California, U.S.A., if you reside outside of the
          U.S.), and that you will accept service of process from the person who
          filed the notice of copyright infringement or an agent of such person;
          and
        </li>
        <li>your physical or electronic signature.</li>
      </ul>
      <p>&nbsp;</p>
      <p>
        Upon receipt of a valid counter notification from you, we are required
        to (i) give a copy of your counter notification to the copyright
        claimant and (ii) inform the copyright claimant that we will restore the
        removed material in 10 business days, unless our designated agent first
        receives notice from the copyright claimant that the copyright claimant
        has filed a court action seeking injunction with respect to your User
        Material.
      </p>
      <p>&nbsp;</p>
      <p>
        Under the DMCA, any person who knowingly makes material
        misrepresentations in a notification of claimed infringement or in a
        counter-notification may be liable for damages.
      </p>
      <p>&nbsp;</p>
      <p id="no-warranties">
        <strong>NO WARRANTIES; LIMITATION OF LIABILITY</strong>
      </p>
      <p>
        THE GREENPARK SERVICES AND ALL CONTENT (INCLUDING VIRTUAL GOODS) ARE
        PROVIDED ON AN &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS,
        WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. TO THE
        FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, WE AND OUR AFFILIATES
        AND SUPPLIERS DISCLAIM ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, WITH
        RESPECT TO THE GREENPARK SERVICES AND ALL CONTENT (INCLUDING VIRTUAL
        GOODS), INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF
        MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AND WARRANTIES OF
        TITLE AND NON-INFRINGEMENT. WITHOUT LIMITATION TO THE GENERALITY OF THE
        FOREGOING, WE AND OUR AFFILIATES AND SUPPLIERS DO NOT WARRANT THAT: (i)
        THE GREENPARK SERVICES AND ALL CONTENT (INCLUDING VIRTUAL GOODS) WILL BE
        ERROR-FREE, ACCURATE OR UP TO DATE, OR WILL BE UNINTERRUPTED, OR WILL BE
        AVAILABLE AT ANY PARTICULAR TIME OR LOCATION; (ii) ANY DEFECT OR ERROR
        ON THE GREENPARK SERVICES OR IN ANY CONTENT (INCLUDING VIRTUAL GOODS)
        WILL BE CORRECTED; OR (iii) THE GREENPARK SERVICES AND ALL CONTENT
        (INCLUDING VIRTUAL GOODS) WILL BE FREE OF VIRUSES OR OTHER HARMFUL
        COMPONENTS.
      </p>
      <p>&nbsp;</p>
      <p>
        YOU UNDERSTAND AND AGREE THAT IN NO EVENT SHALL GREENPARK OR ANY OF OUR
        AFFILIATES OR SUPPLIERS BE LIABLE TO YOU, WHETHER UNDER A THEORY OF
        CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER LEGAL OR
        EQUITABLE THEORY, FOR ANY DIRECT, INDIRECT, INCIDENTAL, EXEMPLARY,
        SPECIAL, PUNITIVE OR CONSEQUENTIAL DAMAGES (INCLUDING, WITHOUT
        LIMITATION, LOSS OF USE, OR LOSS OF DATA OR INFORMATION OF ANY KIND)
        ARISING OUT OF OR RELATED TO THE GREENPARK SERVICES OR ANY CONTENT
        (INCLUDING VIRTUAL GOODS) OR YOUR USE THEREOF, EVEN IF WE OR THEY HAVE
        BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES.
      </p>
      <p>&nbsp;</p>
      <p>
        BECAUSE SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
        CERTAIN WARRANTIES, DAMAGES OR LIABILITIES, SOME OF THE EXCLUSIONS AND
        LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO YOU.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>USER RELEASE AND INDEMNITY</strong>
      </p>
      <p>
        You agree to release and forever discharge (to the fullest extent
        permitted by applicable law) GreenPark and our affiliates and Suppliers,
        including our and their successors and assigns, from any and all claims,
        actions, causes of action, liabilities, damages, costs and expenses
        (including, without limitation, those for bodily injury and emotional
        distress) arising out of or related to: (i) your use of any of the
        GreenPark Services or any Content (including any Virtual Goods); or (ii)
        your User Material.
      </p>
      <p>&nbsp;</p>
      <p>
        IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE
        SECTION 1542 IN CONNECTION WITH THE FOREGOING, WHICH SECTION STATES:{' '}
        <em>
          “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES
          NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF
          EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE
          MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.”
        </em>
      </p>
      <p>
        You further agree to indemnify and hold harmless GreenPark and our
        affiliates and Suppliers, including our and their successors and
        assigns, from and against any third-party claim (including all
        liabilities, damages, losses, costs and expenses associated therewith)
        arising out of or related to: (i) your use or misuse of any of the
        GreenPark Services or any Content (including any Virtual Goods); (ii)
        your violation of these Terms of Use or any applicable law; (iii) your
        User Material; and/or (iv) your violation of any third party&rsquo;s
        rights.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p id="arbitration-agreement">
        <strong>ARBITRATION AGREEMENT AND CLASS ACTION WAIVER</strong>
      </p>
      <p>
        The United States Federal Arbitration Act (9 U.S.C. &sect;&sect; 1-16)
        governs the interpretation and enforcement of this arbitration
        agreement. This arbitration agreement shall survive termination of these
        Terms of Use.
      </p>
      <p>&nbsp;</p>
      <p>
        (1) Except as related to the enforcement of intellectual property
        rights, and except as expressly otherwise provided below, you and
        GreenPark agree to resolve&nbsp;
        <em>on an individual basis through binding arbitration</em>, all
        disputes, controversies, claims and causes of action arising out of or
        related to these Terms of Use or any of the GreenPark Services or any
        Content (including any Virtual Goods), whether based in contract, tort,
        warranty, statute, or any other legal or equitable basis (including,
        without limitation, any dispute relating to the formation, validity,
        interpretation, applicability or enforceability of these Terms of Use,
        the arbitrability of any dispute or claim, and any claim that all or any
        part of these Terms of Use are void or voidable) (collectively,&nbsp;
        <em>&ldquo;Claims&rdquo;</em>&nbsp;and individually, a&nbsp;
        <em>&ldquo;Claim&rdquo;</em>).
      </p>
      <p>&nbsp;</p>
      <p>
        (2) Arbitration is more informal than a lawsuit. There is no judge or
        jury in arbitration, and court review of an arbitration award is
        limited. The arbitrator, however, can award on an individual basis the
        same damages and relief as a court (including injunctive and declaratory
        relief, or statutory damages).&nbsp;BY AGREEING TO ARBITRATE, YOU AND WE
        BOTH AGREE TO UNCONDITIONALLY WAIVE THE RIGHT TO A TRIAL BY JURY OR
        COURT; except that either you or GreenPark may bring an individual
        action in a small claims court for a Claim that is within such court's
        jurisdictional authority.
      </p>
      <p>&nbsp;</p>
      <p>
        (3) Any arbitration between you and us shall be conducted by the
        Judicial Arbitration and Mediation Services, Inc. (
        <em>&ldquo;JAMS&rdquo;</em>), pursuant to the JAMS Streamlined
        Arbitration Rules &amp; Procedures effective July 1, 2014 (the&nbsp;
        <em>&ldquo;JAMS Rules&rdquo;</em>), as modified by this arbitration
        agreement. The arbitration shall be conducted by a single, neutral
        arbitrator, and if you and we cannot agree on the choice of the single
        arbitrator, the arbitrator shall be appointed pursuant to the JAMS
        Rules, with the participation and involvement of you and us pursuant to
        JAMS Rule 12. The JAMS Rules are available on its website at&nbsp;
        <a href="http://www.jamsadr.com/rules-streamlined-arbitration/">
          http://www.jamsadr.com/rules-streamlined-arbitration/
        </a>
        . The Consumer Arbitration Minimum Standards are available at{' '}
        <a href="https://www.jamsadr.com/consumer-minimum-standards/">
          https://www.jamsadr.com/consumer-minimum-standards/
        </a>
        .
      </p>
      <p>&nbsp;</p>
      <p>
        (4) In order to commence arbitration, you or we must first send via
        certified mail to the other a written Notice of Dispute
        (&ldquo;Notice&rdquo;) that sets forth the name, address, and contact
        information of the party giving notice, the Claim(s) and the specific
        facts giving rise to the Claim(s), and the relief requested. Your Notice
        to us must be sent via certified mail to&nbsp;
        <strong>
          GreenPark Sports, Inc., 1290 Howard Avenue, Suite 320, Burlingame,
          California 94010, ATTN.: Dispute.
        </strong>
        &nbsp;We will send any Notice to you at the contact information we have
        for you. It is the sender&rsquo;s responsibility to ensure that the
        recipient receives the Notice. During a period of 45 days following our
        or your receipt of a Notice, you and we will make reasonable efforts to
        resolve the Claim(s) through informal negotiations, and arbitration
        cannot commence during such 45-day period. If we do not resolve the
        Claim(s) within such 45-day period, then either you or we may initiate
        arbitration in accordance with the JAMS Rules.
      </p>
      <p>&nbsp;</p>
      <p>
        (5) The arbitrator must follow these Terms of Use and will have the
        exclusive authority to resolve issues relating to the formation,
        validity, interpretation, applicability and enforceability of these
        Terms of Use (including, without limitation, the arbitrability of a
        dispute/claim, and any claim that all or any part of these Terms of Use
        are void or voidable).
      </p>
      <p>&nbsp;</p>
      <p>
        (6) Any arbitration between you and us must be held either: (i) at a
        location determined pursuant to the JAMS Rules (provided that such
        location is reasonably convenient for you and does not require travel in
        excess of 100 miles from your home or place of business); or (ii) at
        such other location as may be mutually agreed upon by you and us; or
        (iii) if the only Claim(s) in the arbitration is/are asserted by you and
        is/are for less than $10,000 in aggregate, then (at your election) by
        telephone or by written submission.
      </p>
      <p>&nbsp;</p>
      <p>
        (7) The arbitrator shall: (i) apply internal laws of the State of
        California consistent with the Federal Arbitration Act and applicable
        statutes of limitations, or, to the extent (if any) that federal law
        prevails, shall apply the laws of the United States, irrespective of any
        conflict of law principles; (ii) entertain any motion to dismiss, motion
        to strike, motion for judgment on the pleadings, motion for complete or
        partial summary judgment, motion for summary adjudication, or any other
        dispositive motion consistent with California or federal rules of
        procedure, as applicable; (iii) honor claims of privilege recognized at
        law; and (iv) have authority to award any form of legal or equitable
        relief consistent with applicable laws.
      </p>
      <p>&nbsp;</p>
      <p>
        (8) The arbitrator shall issue a written award supported by a statement
        of decision setting forth the arbitrator&rsquo;s determination of the
        dispute/claim and the factual findings and legal conclusions relevant to
        it (an&nbsp;<em>&ldquo;Award&rdquo;</em>). Judgment upon the Award may
        be entered by any court having jurisdiction thereof or having
        jurisdiction over the relevant party or its assets.
      </p>
      <p>&nbsp;</p>
      <p>
        (9) Other than an arbitration initiated by you against GreenPark that
        was determined by the arbitrator to be frivolous or have been brought
        for an improper purpose, we will pay the arbitration filing and
        arbitrator fees, or reimburse you for the payment of such fees.&nbsp; If
        you prevail in the arbitration and the arbitrator issues you an award
        that is greater than the value of our last written settlement offer made
        before an arbitrator was selected (or if we did not make a settlement
        offer before an arbitrator was selected), then we will pay you the
        amount of the award or US$1,000, whichever is greater, and will also pay
        (or reimburse you for) your reasonable attorneys&rsquo; fees and other
        legal expenses incurred in the arbitration. We waive any right to seek
        an award of attorneys&rsquo; fees and expenses in connection with any
        non-frivolous arbitration between you and us.
      </p>
      <p>&nbsp;</p>
      <p>
        (10) You and GreenPark both agree to maintain the confidential nature of
        the arbitration and not to disclose the fact of the arbitration, any
        documents exchanged as part of any mediation or proceedings of the
        arbitration, the arbitrator&rsquo;s decision, or the existence or amount
        of any Award, except as may be necessary to prepare for or conduct the
        arbitration (in which case anyone becoming privy to such confidential
        information must undertake to preserve its confidentiality), or except
        as may be necessary in connection with a court application for a
        provisional remedy, a judicial challenge to an Award or its enforcement,
        or unless otherwise required by applicable law or a valid court order.
      </p>
      <p>&nbsp;</p>
      <p>
        (11) GreenPark may modify the provisions of this arbitration agreement,
        but such modification will only become effective thirty (30) days after
        we have notified you of such modification by email or by posting a
        notice of the changes on the GreenPark Services, and only on a
        prospective basis for disputes/claims arising after the effective date
        of such notification. If any modification to this arbitration agreement
        is deemed illegal, invalid or unenforceable, then this arbitration
        agreement without such modification and in effect at the time of your
        acceptance of these Terms of Use shall govern.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        (12)
        <strong>
          {' '}
          <em>
            <u>CLASS ACTION WAIVER</u>
          </em>
          :{' '}
        </strong>
        YOU AND GREENPARK BOTH AGREE THAT: (i) ANY CLAIM WILL BE BROUGHT IN ANY
        FORUM IN AN INDIVIDUAL CAPACITY ONLY, AND WILL NOT BE BROUGHT ON BEHALF
        OF, OR AS PART OF, ANY PURPORTED CLASS, CONSOLIDATED, OR REPRESENTATIVE
        PROCEEDING; (ii) NEITHER YOU NOR GREENPARK WILL SEEK TO HAVE A CLAIM
        HEARD AS A CLASS ACTION, PRIVATE ATTORNEY GENERAL ACTION, OR IN ANY
        OTHER PROCEEDING IN WHICH EITHER PARTY ACTS OR PURPORTS TO ACT IN A
        REPRESENTATIVE CAPACITY; AND (iii) NO ARBITRATION OR OTHER PROCEEDING
        CAN BE COMBINED WITH ANOTHER WITHOUT THE EXPRESS PRIOR WRITTEN CONSENT
        OF ALL PARTIES TO THE ARBITRATION OR PROCEEDING.&nbsp;If the foregoing
        class action waiver is found to be illegal or unenforceable as to all or
        some parts of a Claim, those parts will be severed and proceed in a
        court of law, with the remaining parts proceeding in arbitration.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p id="governing-law">
        <strong>GOVERNING LAW; JURISDICTION</strong>
      </p>
      <p>
        These Terms of Use shall be governed by and construed in accordance with
        the laws of the State of California, U.S.A., without regard to its
        conflict of laws principles. The United Nations Convention on Contracts
        for the International Sale of Goods, if applicable, shall not apply to
        these Terms of Use.
      </p>
      <p>&nbsp;</p>
      <p>
        Except with respect to Claims to be resolved by binding arbitration in
        accordance with the arbitration agreement set forth in the preceding
        section, you and GreenPark both agree to submit to the exclusive
        jurisdiction of the state or federal courts located in Los Angeles,
        California, U.S.A. to resolve any Claim arising out of or related to
        these Terms of Use or any of the GreenPark Services or any Content
        (including any Virtual Goods) or any User Material.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p id="time-limit">
        <strong>TIME LIMIT ON FILING CLAIMS</strong>
      </p>
      <p>
        YOU UNDERSTAND AND AGREE THAT ANY CLAIM YOU MAY HAVE ARISING OUT OF OR
        RELATED TO THESE TERMS OF USE OR ANY OF THE GREENPARK SERVICES OR ANY
        CONTENT (INCLUDING ANY VIRTUAL GOODS) OR ANY USER MATERIAL, MUST BE
        FILED{' '}
        <strong>
          <em>WITHIN ONE (1) YEAR</em>
        </strong>{' '}
        AFTER SUCH CLAIM AROSE OR BE FOREVER BARRED.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>MISCELLANEOUS</strong>
      </p>
      <p>
        These Terms of Use, together with our{' '}
        <u>
          <a href="https://greenparksports.com/nftterms">NFT Terms</a>
        </u>{' '}
        and all other terms, rules, and policies referenced herein, constitute
        the entire agreement between you and GreenPark concerning the use of the
        GreenPark Services and Content (including Virtual Goods). If any
        provision of these Terms of Use is deemed invalid by a court of
        competent jurisdiction, the invalidity of such provision shall not
        affect the validity of the remaining provisions of these Terms of Use,
        all of which shall remain in full force and effect. No waiver of any
        provision of these Terms of Use shall be deemed a further or continuing
        waiver of such provision or a waiver of any other provision, and our
        failure to assert any right or provision under these Terms of Use shall
        not constitute a waiver of such right or provision. Any provision of
        these Terms of Use that by its nature survives the termination of these
        Terms of Use shall survive such termination.
      </p>
    </div>
  </InfoPage>
);

export default Terms;
