import React from 'react';
import signsGreenie from '../assets/images/community-greenie-signs.png';

const Signs = () => {
  return (
    <div className="signs-section">
      <div className="container section-content">
        <div className="row align-items-center">
          <div className="col-xs-12 col-lg-4">
            <div className="title-text-section">
              <h2 className="section-title white community-headline">
                READY TO JOIN THE PARTY?
              </h2>
              <p className="section-text">
                Right now, our{' '}
                <a
                  href="https://www.instagram.com/greenparksports/"
                  target="_blank"
                >
                  Instagram{' '}
                </a>
                and{' '}
                <a
                  href="https://www.tiktok.com/@greenparksports?"
                  target="_blank"
                >
                  TikTok{' '}
                </a>{' '}
                accounts are the places to be. Want to talk about gameplay and
                the app? Check out our{' '}
                <a href="https://discord.gg/p5p2gPE" target="_blank">
                  Discord channel.
                </a>
              </p>
              <p>
                You can also find us on{' '}
                <a
                  href="https://www.facebook.com/greenparksports/"
                  target="_blank"
                >
                  Facebook
                </a>
                ,{' '}
                <a href="https://twitter.com/greenparksports" target="_blank">
                  Twitter
                </a>{' '}
                and{' '}
                <a href="https://www.youtube.com/c/GreenPark/" target="_blank">
                  YouTube
                </a>
                , but things might be a little quiet for a bit.
              </p>
            </div>
          </div>
          <div className="col-xs-12 col-lg-8">
            <div className="section-image-wrapper">
              <img
                src={signsGreenie}
                alt="signs greenie"
                className="img-fluid section-image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signs;
