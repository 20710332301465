import React, {FunctionComponent} from 'react';

interface HowToItemProps {
  image: string;
  title: any;
  step: string;
  text: string;
}

const HowToItem: FunctionComponent<HowToItemProps> = (
  props: HowToItemProps,
) => {
  return (
    <div className="how-to-item-wrapper">
      <div className="how-to-item">
        {/* 
      <div className="step">
          <span>{props.step}</span>
        </div>
        */}
        <div className="how-to-image">
          <img className="img-fluid" src={props.image} />
        </div>
        <div className="content-wrapper">
          <p className="how-to-item-title">{props.title}</p>
          <p className="how-to-item-description">{props.text}</p>
        </div>
      </div>
    </div>
  );
};

export default HowToItem;
