import React from 'react';
import CookieConsent from 'react-cookie-consent';

export const GPSCookieConsent = () => (
  <CookieConsent
    location="bottom"
    buttonText="OK, I Understand"
    cookieName="greenparksports-cookie-consent"
    expires={150}
    // enableDeclineButton
    // flipButtons
    // declineButtonText="Close"
    buttonClasses="consent-btn"
  >
    About Cookies on Our Website <br />{' '}
    <span className="cookie-consent-subtext">
      We use cookies to personalize and improve your experience on our websites
      and to serve you relevant advertisements. You can visit our{' '}
      <a href="/privacy">Privacy Policy</a> for more information about how we
      use cookies.
    </span>
  </CookieConsent>
);
