import React, {FunctionComponent} from 'react';
import Footer from './Footer';
import Logo from './Logo';

interface InfoProps {
  title?: string | React.ReactNode;
}

const InfoPage: FunctionComponent<InfoProps> = ({title, children}) => (
  <div className="main">
    <div className="subpage">
      <div className="container">
        <div className="row">
          <div className="col">
            <a className="secondary-hero-logo" href="/">
              <Logo />
            </a>
            <div className="subpage-headline">{title}</div>
            {children}
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
);

export default InfoPage;
