import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Community from './Community';
import {ExternalRedirect} from './ExternalRedirect';
import HomePage from './HomePage';
import NbaMarketingConsent from './NbaMarketingConsent';
import NftTerms from './NftTerms';
import PrivacyPolicy from './PrivacyPolicy';
import Signup from './Signup';
import SuperFanOfficialRules from './SuperFanOfficialRules';
import Terms from './Terms';

const Main = () => {
  return (
    <Switch>
      <Route exact path="/" component={HomePage} />
      <Route exact path="/community" component={Community} />
      <Route exact path="/signup" component={Signup} />
      <Route exact path="/privacy" component={PrivacyPolicy} />
      <Route exact path="/terms" component={Terms} />
      <Route exact path="/nftterms" component={NftTerms} />
      <Route
        exact
        path="/superfanofficialrules"
        component={SuperFanOfficialRules}
      />
      <Route
        exact
        path="/nba-marketing-consent-details"
        component={NbaMarketingConsent}
      />
      <ExternalRedirect
        exact
        path={'/invite'}
        link={'https://blog.greenparksports.com/invite'}
      />
      <ExternalRedirect
        exact
        path={'/download'}
        link={
          'https://app.adjust.com/5qr9jx7_3fpdbeb?campaign=InApp&adgroup=Share'
        }
      />
    </Switch>
  );
};

export default Main;
