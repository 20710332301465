import React from 'react';
import bringItGreenie from '../assets/images/gearitems.png';
import TitleText from './TitleText';

const GearItems = () => {
  return (
    <div className="gearitems-section">
      <div className="container section-content">
        <div className="row align-items-center">
          <div className="col-xs-12 col-lg-7">
            <div className="section-image-wrapper">
              <img
                src={bringItGreenie}
                alt="bring it greenie"
                className="img-fluid section-image"
              />
            </div>
          </div>
          <div className="col-xs-12 col-lg-5">
            <TitleText
              title="Rep Your Team In Style"
              titleColor="green"
              text="It’s all about the gear. Earn it. Buy it. Collect it. And get to flexin’ on your rivals."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GearItems;
