import React, {Component} from 'react';
import StepFourImage from '../assets/images/step-four.png';
import StepOneImage from '../assets/images/step-one.jpg';
import StepThreeImage from '../assets/images/step-three.jpg';
import HowToItem from './HowToItem';

export default class HowToGreenpark extends Component {
  render() {
    return (
      <div className="how-to-greenpark-section">
        <div className="how-to-title-wrapper">
          <h3 className="how-to-title">This Is How You Greenpark</h3>
        </div>
        <div className="greenpark-step-list">
          <HowToItem
            image={StepOneImage}
            step="01"
            title={['UNLEASH YOUR ', <br key={2} />, 'INNER SUPERFAN']}
            text="Create your superfan avatar and join forces with friends and fellow fans."
          />
          {/* <HowToItem
            image={StepTwoImage}
            step="02"
            title={['COMPETE IN ', <br key={2} />, ' EPIC BATTLES']}
            text="Prove your fandom in game prediction battles using real-time sports data."
          /> */}
          <HowToItem
            image={StepThreeImage}
            step="03"
            title={['GET ', <br key={2} />, ' SOCIAL']}
            text="Chat with friends, celebrate with fellow fans or talk some smack to your rivals."
          />
          <HowToItem
            image={StepFourImage}
            step="04"
            title={['GRAB ', <br key={2} />, ' OFFICIAL GEAR']}
            text="Get your swagger on. With official gear from all of your favorite teams."
          />
          {/* <HowToItem
            image={StepFiveImage}
            step="05"
            title={['EARN ', <br key={2} />, ' REWARDS']}
            text="Bring your A game to get the bag. The more you play, the more rewards you win."
          />
          <HowToItem
            image={StepSixImage}
            step="06"
            title={['WIN FANERGY ™', <br key={2} />, ' LEVEL UP']}
            text="Climb the leaderboard and rally your team to show the world you’re the #1 fan."
          /> */}
        </div>
      </div>
    );
  }
}
